@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&family=Open+Sans:wght@300;400;500;600&display=swap');

.m-5{
width:100%;
margin: 10px 20px 20px 20px!important;
}

.divCategorias{
    display: flex!important;
    flex-direction: column!important;
    gap: 15px!important;
}

.nomeCategoria{
    font-weight: 700;
    font-size: 20px;
    color: #4a4a4a;
}
  
.resultCategoria{
    border-radius:10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 20px;
    align-items: center;
    min-height: 100px;
}

.editaCategoria{
    transition: all .1s;
}
.editaCategoria:hover{
    font-size: 18px;
}
.deletaCategoria{
    transition: all .1s;
}
.deletaCategoria:hover{
    font-size: 18px;
}

.inputGroup-adicinaCategoria{
    justify-content: end!important;
    gap: 40px;
    grid-row-gap: 0px;
}

.disponibilidadeCategoria{
    margin-left: 20px;
}

.custom-checkbox {
    width: 30px;
    height: 30px;
    border: 1px solid #a4a4a4;
    display: inline-block;
    position: relative;
    border-radius: 100px;
    margin: 0px 3px;
    color: #a4a4a4;
    background: #e8e8e8;
    transition: all .2s;
    cursor: pointer;
    opacity: 0.5;
}

.custom-checkbox:hover {
    border: 1px solid rgb(49, 140, 213);
    color: rgb(49, 140, 213)!important;
    background: white;
    opacity: 1;
}

.custom-checkbox p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom-checkbox.checked {
    background-color: rgb(49, 140, 213);
    border:none;
    color:white!important;
    opacity: 1;
}

.custom-checkbox.checked i {
    display: block;
}

.disponibilidadeH1{
    font-size: 0.75rem;
    font-weight: 600;
    color: #000000c0;
    margin-bottom: 0px;
    --line-height: 1.2;
}

.selectdiv.disp select {
    margin-top: 13px!important;
    height: 44px!important;
}

.selectdiv.disp select:focus-visible {
    outline:1px solid rgb(49, 140, 213)!important;
}

.selectdiv.disp {
    min-width: 80%;
}


.selectdiv.disp:after {
    top:23px;
}

.divDiaHorario{
    display: flex;
    flex-direction: row;
    height: 100px;
    gap: 50px;
    border: 1px solid lightgray;
    border-radius:15px;
    padding:10px;
    background: white;
}
.contentHeaderDisponibilidade{
    height: 90px;
}

.contentInfoDisponibilidade{
    gap: 20px;
    display: flex;
    flex-direction: column;
    margin-right: 10%;
}

.buttonAddHorarios{
    background: transparent;
    color: rgb(49, 140, 213)!important;
    border: none;
    max-width: 200px;
}

.divTimeSet{
    input{
        border-radius: 8px;
        border: none;
        box-shadow: 0px 0px 2px 1px lightgray;
        padding: 5px 5px 5px 12px;
    }
    input:focus-visible{
        outline:1px solid rgb(49, 140, 213)!important;
    }
}

.columnDia{
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    gap: 5px;
}


.columnHorario{
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-top: -5px;
}

.divPaiCategorias{
    gap: 15px;
    display: flex;
    flex-direction: column;
}

.categoriaPool{
    display: flex;
    flex-direction: row;
    gap: 80px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    min-height: 62px;
}

.blocoAdicionaisExibidos{
    border: 1px solid lightgray;
    margin: 10px;
    border-radius: 10px;
    &:hover{
        background-color:#f7f7f7 !important;
    }
}

.itemPool{
    --border-top: 1px solid lightgray;
    width: 100%;
    padding: 0px 9px;
}

.adicionaisPool{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: none;
    margin: 0px 20px 20px 20px;
    --border: 1px solid lightgray;
    --border-radius: 0px 0px 5px 5px;
    --padding: 10px;
}

.columnDeleteHorario{
    justify-content: center;
    display: flex;
    align-items: center;
}

.input-group-edit.modalCategoria{
    justify-content: end!important;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 500px; /* You can adjust this value to your liking */
    height: fit-content;
}

.list-header-categories {
    margin-bottom: 0rem !important;
    display: flex;
    justify-content: space-between;
    --width: 100%;
}

div.align-mobile {
    text-align: start !important; 
}

.smoothShowAndHideDropdown {
    max-height: 0px;               /* Começa com a altura 0 */
    overflow: hidden;            /* Oculta o conteúdo que ultrapassa a área */
    transition: all 1s; /* Transição suave da max-height */
    visibility: hidden;
}

.smoothShowAndHideDropdown.expandedItemCardapio {
    max-height: 10000px; /* Um valor suficientemente alto para garantir que o conteúdo caiba */
    transition: all 1s; /* Animação suave durante a expansão */
    visibility: visible;
}

.dragIndicatorCategoria{
    font-size: 30px;
    color: darkgray;

    &:hover{
        color: white;
    }
}

.dropDownIconForAdicionais{
    color:rgb(49, 140, 213);
    width: 20px;
    cursor: pointer;
    &:hover{
        color: white;
    }
}

.dropDownIconForAdicionaisGray{
    color:#5a5a5a;
    width: 20px;
    cursor: pointer;
    &:hover{
        color: white;
    }
}