.inputGroup-Adicionais{
    justify-content: start !important;
    gap: 40px;
    grid-row-gap: 0px;
}

.quantity-control {
    display: flex;
    justify-content: start;
    align-items: center;
    --gap: 30px;
}

.quantity-control-individual{
    display: flex;
    flex-direction: column;
}
  
.input-groupCadastroAdicionais {
    margin: 0px -30px;
}
  
.input-groupCadastroAdicionais label {
    margin-right: 5px;
}

.input-groupCadastroAdicionais input {
    text-align: center;
    width: 50%;
    margin: 0 5px;
    padding: 12px 50px;
}

.input-groupCadastroAdicionais button {
    width: 25px;
    --height: 25px;
    padding: 0;
    position: relative;
    border: none;
    color: gray;
    border-radius: 5px
}

.btnDecrement_{
    left: 40px;
}

.btnIncrement_{
    right: 40px;
}

.labelOptAdicionais{
    display: flex;
    flex-direction: column;
    label{
        font-size: 14px;
        font-weight: 600;
        color: #333;
    }   
    span{
        font-size: 12px;
        color: #999;
    } 
}

.divEditRmvSubAdicionais{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    position: relative;
    svg{
        cursor: pointer;
    }
}

.radio-button-groupAdicionais {
    display: flex;
    flex-direction: column;
}

.radio-optionAdicionais {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.radio-optionAdicionais input[type="radio"] {
    margin-right: 5px;
}

.containerBox-adicionais{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.containerBox-adicionaisModalRow{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.row-adicionais-1{
    display: flex;
    justify-content: center;
}
.row-adicionais-2{
    display: flex;
    justify-content: center;
}

.divDescricaoAdicionais{
    --align-items: center;
    --display: flex;
    margin-top: 2px;
    font-weight: 500;
    font-size: 18px;
    color: #777;
    --width: auto; /* ajuste a largura conforme necessário */
    white-space: nowrap; /* impede a quebra de linha */
    overflow: hidden; /* esconde o texto que excede a largura */
    text-overflow: ellipsis; /* adiciona reticências no fim do texto visível */
}

.valorDoAdicional{
    font-weight: 600;
    font-size: 18px;
    color: #4a4a4a;
    min-width: 100px;
}

.resultCategoriaAdicionais{
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    align-items: center;
    min-height: 70px;
}

.resultAdicionaisChildren{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    border-radius: 0px 0px 10px 10px;
    background-color: transparent;
    border-top: 1px solid #eee;
}

.divCadastroAdicionalChildren{
    display: flex;
    justify-content: center;
    --padding-top: 15px;
    span{
        border: 1px solid #0d6efd;
        color: #0d6efd;
        padding: 3px 10px;
        border-radius: 5px;
        --margin-right: 50px;
        cursor:pointer;
        transition: all 0.2s;
        &:hover{
            background-color: #0d6efd;
            color: #fff;
        }        
    }
}

.divMandatory{
    display: flex;
    justify-content: start;
    align-items: center;
    span{
        font-size: 10px;
        color: #fff;
        background: black;
        padding: 0px 5px;
        border-radius: 5px;
    }
}

.divOpcional{
    display: flex;
    justify-content: start;
    align-items: center;
    span{
        font-size: 10px;
        color: #000;
        padding: 0px 5px;
        border-radius: 5px;
        border: 1px solid black;
    }
}

.divItemAdicionalInfo{
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 0px 52px 15px 52px;
    justify-content: space-between;
    --margin-bottom: 15px;
    align-items: center;
}