@media only screen and (max-width: 1366px) and (max-height: 768px) {
    :root {
    --main-color-pedeja: rgb(49, 140, 213);;
    }

    .m-5 {
    background: rgb(247,247,247)!important;
    }

    .m-5.pdv{
    display: inline-flex;
    height: 78vh;
    }

    *{
    margin:0;
    padding:0;
    box-sizing: border-box;
    }


    .container{
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-flow: row wrap;
    }

    .container-menu{
    width: 50%;
    min-height: 100vh;

    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;

    padding: 15px;

    background-color:#F8FAF9;
    }


    .container-direita{
    width: 50%;
    min-height: 100vh;

    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;

    padding: 15px;

    background-color:#1E32FF;
    }

    .buttons-menu{
    width: 100px;
    }

    .input-box-list.home{
    margin-bottom: 0px!important;
    }

    .input-fieldClienteOuPedido{
    box-shadow: 0px 0px 3px 2px lightgray!important;
    }

    .continue-button.cfgHome button{
    background: white!important;
    width:50px!important;
    --border: 1px solid black!important;
    box-shadow: 0px 0px 3px 2px lightgray;
    }

    .continue-button.cfgHome{
    width:50px!important;
    }

    .input-box.number{
    display: inline-block!important;
    --width: 5%!important;
    input{
        padding: 0.8rem 0px 10px 0px!important;
        text-align: center;
        min-width: 60%;
    }
    }

    .decrement{    
    border:none;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    background: white;
    box-shadow: 0px 0px 1px 1px lightgray;
    margin-right: 5px;
    svg{
        color:#4a4a4a;
    }
    }

    .decrement.disable{    
    border:none;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    box-shadow: 0px 0px 1px 1px lightgray;
    margin-right: 5px;
    pointer-events: none;
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.5;
    svg{
        color:#4a4a4a;
    }
    }


    .increment{    
    border:none;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    background: white;
    box-shadow: 0px 0px 1px 1px lightgray;
    margin-left: 5px;
    svg{
        color:#4a4a4a;
    }
    }

    .line{
    height: 2px;
    width: 100%;
    background: lightgray;
    margin-left: 5px;
    }

    .line-separator{
    height: 1px;
    width: 100%;
    background: lightgray;
    --margin-left: 0px;
    }

    .subtitle-form-tempo-entrega{
    color:gray;
    font-weight:bold;
    display: flex;
    align-items: center;
    --width: 280px;
    }

    .configure-tempo-entrega{
    display: inline-grid;
    width: 50%;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    .itens-cardapio{
    width: 70%;
    }

    .gerar-pedido{
    --width: 30%;
    background: white;
    border-radius: 10px;
    --height: 84vh;
    --margin-top: -25px;
    box-shadow: 0px 0px 1px 1px lightgray;
    }

    .grid-cardapio{
    background: white;
    border-radius: 10px;
    height: 97%;
    margin-top:10px;
    box-shadow: 0px 0px 1px 1px lightgray;
    }


    .cardapio-filtro{
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
    height: 8%;
    justify-content: space-between;
    span{
        font-weight:bold; 
        color:rgb(49, 140, 213); 
        cursor:pointer;
        margin-left: 20px;
    }
    }

    .cardapio-avancar{
    border-top: 1px solid lightgray;
    height: 6%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    }

    .center-cardapio{
    height: calc(100vh - 220px);
    display: inline-flex;
    width: 100%;
    }

    .categoria-cardapio{
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 10px 10px 10px;
    position: relative;
    scroll-behavior: smooth;
    border-right: 1px solid #e1e1e1;
    }

    .categoria-item{
    width: 100%;
    min-height: 50px;
    max-height: 50px;
    border-radius: 5px;
    border: 1px solid #D7D8DD;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .2s;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    }

    .categoria-item:hover{
    background-color: rgb(49, 140, 213);
    span{
        color: white;
    }
    }

    .categoria-item-title{
    align-items:center;
    text-align: center;
    display:flex;
    justify-content:left;
    margin: 5px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 12px;
    }

    .itens-cardapio{
    width: 80%;
    }


    .overflow-itens{
    width: 80%;
    overflow-y: scroll;
    padding: 0px 15px 10px 15px;
    height: calc(100vh - 220px);
    }

    .itens-categoria{
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    gap: 10px;
    flex-wrap: wrap;
    }

    .itens{
    cursor: pointer;
    border-radius: 5px;
    width: 100px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #D7D8DD;
    transition: all .2s;
    position: relative;    
    }

    .itens:hover{
    background-color: rgb(49, 140, 213);
    border: 1px solid rgb(49, 140, 213);
    }

    .itens:hover .divItemName { 
    border:none;
    span{
        color: white;
    }
    } 

    .divItemImg{
    height: 75px;
    box-sizing: border-box;
    img{
        border-radius: 10px 10px 0px 0px;
        border-radius: 5px 5px 0 0;
        width: 98px;
        height: 75px;
        object-fit: cover;
        box-sizing: border-box;
    }
    }

    .divItemName{
    width: 100%;
    height: 100%;
    border-radius: 0 0 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #D7D8DD;
    box-sizing: border-box;
    transition: all .2s;

    span{
        max-width: 86px;
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
        line-height: 15px;
        color: #5a5a5a;
        line-break: auto;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        transition: all .2s;
    }
    }

    .continue-button.avancar{
    button{
        height: 34px!important;
        width: 130px!important;
        margin-top: 2px!important;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size:14px;
        font-weight: bold;
    }
    }

    .continue-button.sair{
    button{
        height: 34px!important;
        width: 50px!important;
        margin-top: 2px!important;
        background: lightgray!important;
        color: gray!important;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    }

    .continue-button.observacao{
    button{
        height: 34px!important;
        width: 130px!important;
        margin-top: 2px!important;
        background: lightgray!important;
        color: gray!important;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size:14px;
        font-weight: bold;
    }
    }

    .sairObservacao{
    display: inline-flex;
    align-items: center;
    width:20%;
    margin-left: 20px;
    margin-top: 15px;
    }

    .pedeja-input{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: max-content;
    align-items: center;
    }

    .input-number{
    width: 92px;
    position: relative;
    font-family: Roboto,sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    input{
        height: 30px;
        width: 92px;
        border-radius: 5px;
        padding: 0 28px;
        border: 1px solid #D7D8DD;
        font-size: 10px;
        color: #2b2b2b;
        position: relative;
        text-align: center;
    }
    }

    .item-selected-amount{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    padding-bottom: 6px;
    }

    .decrement-item{
    transition: .3s;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 2%;
    border: none;
    background-color: #d7d8dd;
    color: #2b2b2b;
    width: 17px;
    height: 17px;
    top: calc(50% - 8.5px);
    left: 6px;
    }
    .increment-item{
    transition: .3s;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 2%;
    border: none;
    background-color: #d7d8dd;
    color: #2b2b2b;
    width: 17px;
    height: 17px;
    top: calc(50% - 8.5px);
    right: 6px;
    }

    .divItemSelected{
    width: 100%;
    height: 100%;
    border-radius: 0 0 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    transition: all .2s;
    color: white;
    span{
        max-width: 86px;
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
        line-height: 15px;
        line-break: auto;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        transition: all .2s;
    }
    }

    .item-price-selected{
    background-color: #fff;
    color: #003e63;
    border-radius: 5px;
    padding: 4px 6px;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    width: max-content;
    transition: all .2s;
    }

    .divInputItem{
    height: 30px;
    width: 92px;
    border-radius: 5px;
    padding: 0 28px;
    border: 1px solid #D7D8DD;
    font-size: 10px;
    color: #2b2b2b;
    input{
        position: relative;
        text-align: center;
    }
    }

    .buttons-edo{
    display: flex;
    justify-content: space-between;
    padding: 5px 20px 5px 20px;
    height: 8vh;
    }

    .itens-pedido{
    display: flex;
    justify-content: space-between;
    padding: 5px 20px 5px 20px;
    font-weight: bold;
    background-color: #ccc;
    font-size: 10px;
    height: 4%;
    align-items: center;
    }


    .buttons-edit-delete-obs{
    text-align: center;
    }


    .buttons-edit-delete-obs button {
    border: none;
    box-shadow: 1px 1px 6px lightgray;
    height:40px;
    background:white;
    border:solid 2px var(--main-color-pedeja);
    width:100px;
    background: -webkit-linear-gradient(to left, #2D74FF, #07BFFF);
    background: -o-linear-gradient(to left, #2D74FF, #07BFFF);
    background: -moz-linear-gradient(to left, #2D74FF, #07BFFF);
    background: linear-gradient(to left, #2D74FF, #07BFFF);
    background: white!important;
    padding: 0.62rem;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    }

    .buttons-edit-delete-obs button:hover {
    background: -webkit-linear-gradient(to left, #4281FF, #51D2FF);
    background: -o-linear-gradient(to left, #4281FF, #51D2FF);
    background: -moz-linear-gradient(to left, #4281FF, #51D2FF);
    background: linear-gradient(to left, #4281FF, #51D2FF);
    }

    .buttons-edit-delete-obs button a {
    text-decoration: none;
    font-size: 10px;
    font-weight: 700;
    color: var(--main-color-pedeja)!important;
    }

    .buttons-edit-delete-obs button svg {
    color: var(--main-color-pedeja)!important;
    }

    .lista-pedidos{
    display: flex;
    gap: 5px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    height: 35px;
    padding-left: 15px;
    height: 50px;
    }

    .content-pedidos{
    height: 42%;
    overflow-x: hidden;
    overflow-y: scroll;
    }

    .resumo-valores{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    padding: 0 20px;
    height: 50px;
    font-size:10px
    }

    .valores-categoria{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #5a5a5a;
    position: relative;
    }

    .separador-valor{
    width: 100%;
    box-sizing: border-box;
    background-color: #e1e1e1;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
    height: 25px;
    }

    .box-info{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px 5px;
    transition: all .2s;
    margin-bottom: 1px;
    }


    .informacoes-pedido{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 10px 5px;
    transition: all .2s;
    }

    .container-cliente-pedido{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    }

    .cliente-pedido-data{
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    gap: 10px;
    max-width: 768px;
    position: relative;
    border: 2px solid rgb(49, 140, 213);
    }

    .telefone{
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    z-index: 2;
    background-color: #fff;
    }

    .input-data-cliente{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    position: relative;
    input{
        border: none;
        outline: none;
        height: 30px;
    }
    }

    .client-data-separator{
    width: 1px;
    background-color: rgb(49, 140, 213);
    height: 40px;
    z-index: 2;
    }

    .grid-botoes-pedido{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 10px;
    }

    .button-column{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 5px;
    button{
        min-width: 30px;
        padding: 0 30px;
        height: 25px;
        width: 100%;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        border-radius: 5px;
        box-sizing: border-box;
        font-weight: 600;
        font-family: Roboto,sans-serif;
        cursor: pointer;
        color: #fff;
        background-color: rgb(49, 140, 213);
        transition: background-color .3s;
    }            
    }


    .button-enter{
    min-width: 50px;
    height: 50px;
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 5px;
    box-sizing: border-box;
    font-weight: 500;
    font-family: Roboto,sans-serif;
    cursor: pointer;
    color: #fff;
    background-color: rgb(49, 140, 213);
    transition: background-color .3s;
    }

    .footer{
    height:40%;
    box-sizing: border-box;
    border-top: 1px solid lightgray;
    }

    div[type="button"] {
        margin-top: 14px;
    }

}