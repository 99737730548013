.nav-inicio{
    width: 50px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;
}

.nav-carrinho{
    width: 50px;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;
}

.imgAdicional{
    height: 50px;
    width: 50px;
    border-radius: 7px;
}

.icon-ativo{
    background-color: rgb(16, 149, 243);
    position: absolute;
    top: -2px;
    width: 50px;
    height: 2px;
}

.searchIconMobile{
    background-color: rgb(217, 244, 255);
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.company-info {
    margin-top: 56px;
    height: 40px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text-mobile{
    font-size: 10px;
    font-weight: 500;
    line-height: 8px;
    color: #2b2b2b;
}

.dot{
    background-color: #9d9d9d;
    width: 3px;
    height: 3px;
    border-radius: 50%;
}

.linkPerfilLoja{
    color: rgb(16, 149, 243);
    font-size: 10px;
    font-weight: 600;
    line-height: 8px;
    cursor: pointer;
}

.tab-wrapper{
    padding-bottom: 56px;
}

.tab-list{
    z-index: 998;
    overflow-x: scroll;
    position: sticky;
    border-bottom: 0.2px solid rgba(215,216,221,.5);
    border-top: 0.2px solid rgba(215,216,221,.5);
    background-color: #fff;
}

.tab-list.fixed{
    top: 55px;
    width: 100%;
    position: fixed;
}

.tab-list_content.marginTop{
    margin-top: 32px;
}


@supports (scrollbar-color: #999 #f5f5f5) {
    .tab-list {
        scrollbar-color: #999 #f5f5f5;
        scrollbar-width: none;
    }

}

.tab-item-list{
    height: 30px;
    align-items: center;
    display: flex;
    margin-bottom: 0px!important;
}

.tab-list_item:first-child{
    padding-left: 0px!important;
}

.tab-list_item{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    white-space: nowrap;
    height: 100%;
    position: relative;
    font-size: 13.2px;
}

.tab-list_item_categoria_nome{
    text-overflow: ellipsis;
    --overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-weight: 700;
}

.tab-list_item_ativo{
    background-color: rgb(16, 149, 243);
    display: flex;
    position: absolute;
    text-align: center;
    width: 90%;
    border-radius: 4px;
    height: 1px;
    bottom: 0;
}

.tab-list_content{
    margin-top: 30px;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.categoria-container{
    padding: 16px 16px 24px;
}

.title-container-mobile{
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: 8px;
    h2{
        color: #333;
        font-size: 18px;
        font-weight: 700;
        line-height: 16px;
    }
}

.itens-mobile{
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.itens-container-mobile:first-child{
    border-top: 2px solid #ebebeb;
}

.itens-container-mobile{
    border-bottom: 2px solid #ebebeb;
}

.item-card{
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    border: 0;
    outline: 0;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none;
}

.text-info-mobile{
    display: flex;
    flex-direction: column;
    gap: 4px;
    h4{
        line-height: 16px;
        white-space: pre-line;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #5a5a5a;
        font-size: 14px;
        font-weight: 600;
    }
    .description-mobile{
        color: #595959;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        white-space: pre-line;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.price-mobile{
    color: rgb(16, 149, 243);
    font-weight: 700;
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 3px;
}

.picture-mobile{
    width: inherit;
    height: inherit;
    display: block;
    border-radius: 4px;
    img{
        border-radius: 4px;
        object-fit: cover;
    }
}

.mapContainerCategorias{
    padding-top:40px;
}

.mapContainerCategorias:first-child{
    padding-top:0px;
}

.backButtonDetalhe{
    position: fixed;
    top: 21px;
    left: 13px;
    padding: 0;
    padding-right: 2px;
    background-color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.compartilharButtonDetalhe{
    position: absolute;
    top: 21px;
    right: 13px;
    z-index: 10;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    padding-right: 2.2px;
    cursor: pointer;
}

.calculandoDistanciaLoading{
    fill:#1095F3!important;
    width: 64px!important;
    height: 64px!important;
    position: absolute;
    top: 41%;
    left: 41%;
}

.infoBoxContainer{
    padding-right: 18px;
    padding-left: 18px;
    margin-bottom: 12px;
    margin-top: 12px;
    width: 100%;
}

.payment-option-stored{
    padding: 10px;
    gap: 10px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
}

.divContendoAdicionais {
    padding: 20px;
    border-top: 1px solid lightgray;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.divContendoAdicionais.first {
    border-top: none;
}

.adicionalTitleText{
    width: 100%;
    align-items: start;
    display: flex;
    flex-direction: column;
    span {        
        font-weight: 700;
        font-size: 15.4px;
        color: #333;
    }
    p{
        margin-bottom: 10px;
    }
}

.childrenDivTitleAdicionalCardapio{
    margin-top: 10px;
    display: flex;
    position: relative;
    align-items: center;
    right: 0px;
    width: 100%;
    label{
        background: black;
        color:white;
        font-size: 10px;
        border-radius: 5px;
        padding: 0px 5px;
    }
}

.childrenDivTitleAdicionalCardapio label, .childrenDivTitleAdicionalCardapio svg {
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    right: 0;
    transition: all .3s;
    transform: translateX(-15px);
    opacity: 0;
    visibility: hidden;
}

.childrenDivTitleAdicionalCardapio label.visible, .childrenDivTitleAdicionalCardapio svg.visible {
    opacity: 1;
    transition: all .3s;
    visibility: visible;
    transform: translateX(0);
}

@keyframes slideDown {
    0% {
        transform: translateY(-15px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(15px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.animated-number {
    display: inline-block;
    animation-fill-mode: both;
}

.slideDown {
    animation-name: slideDown;
    animation-duration: 0.2s;
}

.slideUp {
    animation-name: slideUp;
    animation-duration: 0.2s;
}

.adicional-controls{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.qtdMinMaxDoAdicional{
    position: absolute;
    top: 2px;
    right: 22px;
    color: #ccc;
    font-size: 10px;
    font-weight: 600;
}

.containerAdicionalInfoCarrinho{
    color: #333;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 0px;
}

.divAdicionalInfoCarrinho{
    display: flex;
    color: #bbb;
    padding-left: 15px;
    flex-direction: column;
}

.obsTextBoxContainerModalAddItem{
    padding-right: 18px;
    padding-left: 18px;
    margin-bottom: 12px;
    margin-top: 12px;
    width: 100%;
}

.text-adicional-item{
    align-items: center;
    display: flex;
    gap: 5px;
}

.avancarDetalhe{
    position: fixed;
    right: 0;
    left: 0;
    padding-right: 18px;
    padding-left: 18px;
    padding-top: 6px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 -15px 45px 0 rgba(10,10,10,.1);
    bottom: 0;
}

.btnAvancarDetalhe {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 50px;
    padding: 0 10px;
    min-height: 50px;
    width: 100%;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 4px;
    font-weight: 700;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    transition: all .2s;
    background-color: #1095F3;
    color: #FFFFFF;
}

.btnDisabledStyle {
    background-color: #cccccc; /* Cinza como desabilitado */
    color: #FFFFFF;            /* Fonte branca para contraste */
}

.btndata{
    font-weight: 800;
    font-size: 14px;
    line-height: 15px;
    text-align: right;
    padding-left: 10px;
    color: #FFFFFF;
}

.observacoesDetalhe{
    padding-right: 24px;
    padding-left: 24px;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    background-color: #ebebeb;
    min-height: 48px;
}

.obsTextBox{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.obsText{
    width: 100%;
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    span {
        font-weight: 700;
        font-size: 15.4px;
        color: #333;
    }
}

.obsTextBoxContainer{
    padding-bottom: 72px;
    padding-right: 18px;
    padding-left: 18px;
    margin-bottom: 12px;
    margin-top: 12px;
    width: 100%;
}

.qtdBoxContainer{
    padding-right: 18px;
    padding-left: 18px;
    margin-bottom: 0px;
    margin-top: 12px;
    width: 100%;
    border-top: 2px solid #ebebeb;
    --border-bottom: 2px solid #ebebeb;
    padding-top: 6px;
    padding-bottom: 6px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    min-height: 50px;
}

.qtdDataCounter{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    position: relative;
}

.quantityText{
    font-size: 13.2px;
    font-weight: 700;
    color: #333;
}

.obsTextBoxData{
    width: 100%;
}

.text-areaObs{
    resize: vertical;
    min-height: 100px;
    border: 1px solid #d3d3d3;
    background: #fff;
    padding-left: 12px;
    padding: 6px;
    width: 100%;
    border-radius: 0.4em!important;
    white-space: pre-wrap!important;
    overflow: auto!important;
    --border: none;
    --box-shadow: 1px 1px 6px lightgray;
    border-radius: 10px;
    margin: 0.6rem 0 0.2rem 0;
    padding: 0.5rem 1rem;
}

.text-areaObs:focus-visible{
    outline: 1px solid #4070f4;
}

.carrinhoHeader{
    height: 50px;
    border-bottom: 2px solid #ebebeb;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.backButtonCarrinho{
    padding: 0;
    padding-right: 2px;
    background-color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.carrinhoContainer{
    background: #fff;
    min-height: calc(100vh - 50px);
    padding-bottom: 90px;
}

.btnAdicionarItens{
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    button{
        min-width: 40px;
        padding: 0 10px;
        min-height: 40px;
        width: 100%;
        font-size: 14px;
        background: #fff;
        border: 2px solid #1095F3;
        border-radius: 4px;
    }
    span{
        color : #1095F3;
        font-weight: 500;
    }
}

.itemNoCarrinho{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border-bottom: 1px solid #d9d9d9;
    padding: 16px;
}

.wrapItensCarrinho{
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 4px;
    align-items: flex-start;
}

.rowItensStart{
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
}

.imgItemCarrinho{
    img{
        width:52px;
        border-radius: 0.6em !important;
    }
}

.infoItemCarrinho{
    width: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.itemTitleCarrinho{
    font-weight: 700;
    font-size: 13.2px;
    color: #333;
}

.itemPriceCarrinho{
    color: rgb(16, 149, 243);
    display: flex;
    align-items: baseline;
    font-weight: 600;
    font-size: 13.2px;
}

.itemObsCarrinho{
    color: rgb(16, 149, 243);
    line-height: 16px;
    letter-spacing: .05em;
    font-size: 11px;
}

.controlQtdItem{
    height: 52px;
    display: flex;
    align-items: center;
}

.btnsForQtd{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    height: 43px;
    position: relative;
}

.borderBoxQtdCarrinho{
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    display: block;
}

.downAndCounter{
    display: flex;
    transition: all .2s;
    button{
        font-size: 33px;
        border-radius: 0.2em !important;
        min-width: 30px;
        padding: 0 8px;
        min-height: 30px;
        width: 100%;
        font-size: 12px;
        border: none;
        outline: none;        
        background: #fff;
    }
}

.trashIconCarrinho{
    display: flex;
    justify-content: center;
}

.counterQtdItemCarrinho{
    width: 40px;
    padding-right: 12px;
    padding-left: 12px;
    font-weight: 500;
    font-size: 17.6px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.btnAddCounter{
    font-size: 33px;
    border-radius: 0.2em !important;
    min-width: 30px;
    padding: 0 8px;
    min-height: 30px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all .2s;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: transparent;
    p{
        color: #1095F3;
        margin-bottom: 0px!important;
    }
}

.whatsappNumberFinalizarPedido{
    padding-bottom: 10px;
    letter-spacing: .05em;
    color: #333;
    font-size: 13.2px;
    font-weight: 700;
}

.whatsappNumberFinalizarPedidoInput{
    border: 1px solid #a7a7a7;
    border-radius: 4px;
    font-size: 16px;
    transition: all .2s;
    height: 60px;
    padding: 20px;
}


.avancarFinalizarPedido{
    --position: fixed;
    --right: 0;
    --left: 0;
    padding-right: 18px;
    padding-left: 18px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    flex-direction: column;
}

.btnAvancarFinalizarPedido{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    padding: 0 10px;
    min-height: 50px;
    width: 100%;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 4px;
    font-weight: 700;
    user-select: none;
    cursor: pointer;
    transition: all .2s;
    background-color: #1095F3;
    color: #FFFFFF;
    p{
        font-weight: 800;
        font-size: 16px;
    }
}


.checkout-form {
    font-family: Arial, sans-serif;
    max-width: 400px;
    margin: auto;
    padding: 16px;
    background-color: #fff;
    color: #333;
    border-radius: 8px;
    --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.checkout-form h2,
.checkout-form h3,
.checkout-form h4,
.checkout-form p {
    margin: 4px 0;
}

.checkout-form .btnChangeFimPedido {
    background-color: white;
    color: #1095F3;
    border: 2px solid #1095F3;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    display: flex;
}

.delivery-info{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
}

.delivery-method{
    border: 2px solid #1095F3;
    border-radius: 4px;
}

.delivery-method .withAddress{
    background: #1095F3;
    color: white;
    padding: 10px;
    margin: 0px;
    display: flex;
    font-weight: 600;
}

.delivery-method .withAddressSelected{
    background: rgb(230,230,230);
    color: rgb(90, 90, 90);
    padding: 10px;
    margin: 0px;
    display: flex;
    font-weight: 600;
}

.payment-method{
    background: rgb(230,230,230);
    color: rgb(90, 90, 90);
    margin: 0px;
    display: flex;
    font-weight: 600;
    flex-direction: column;
    border: 2px solid rgb(230,230,230);
    border-radius: 4px;
    margin-bottom: 12px;
}

.payment-method-selected{
    background: #1095F3;
    color: white;
    margin: 0px;
    display: flex;
    font-weight: 600;
    flex-direction: column;
    border: 2px solid #1095F3;
    border-radius: 4px;
    margin-bottom: 12px;
}

.payment-options{
    background:white;
    color:rgb(90, 90, 90);
}

.payment-option{
    padding: 10px;
    gap:10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid rgb(230,230,230);
}

.payment-option-selected{
    padding: 10px;
    gap:10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payment-option:first-child{
    border-top: none;
}

.counterItensCarrinhoNum{
    background: #b7dffb;
    color: #0c77c2;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 30px;
    top: 5px;
    font-style: normal;
    z-index: 10;
}

@keyframes pulsar {
    0%, 100% {
        transform: scale(1); /* Tamanho original */
    }
    60% {
    transform: scale(1.3); /* Aumenta 50% */
    }
}

.counterItensCarrinhoNumAnimated {
    width: 16px; /* Largura inicial */
    height: 16px; /* Altura inicial */
    background-color: #67a5d1; /* Cor de fundo */
    animation: pulsar 1s infinite; /* Aplica a animação */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 30px;
    top: 5px;
    z-index: 1;
}

.buttonNewAddress{
    width:120px; 
    margin-left:15px;
    background: #1095F3;
    border: 2px solid white;
    align-items: center;
    border-radius: 4px;
    display: flex;
    justify-content: center;
}

.delivery-options{
    padding: 5px 10px 0px 10px;
    border-bottom: 1px solid lightgray;
}

.checkRetirada{
    border: 1px solid rgb(160, 160, 160);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
}

.summary{
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    padding:16px;
}

.subtotal{
    display: flex;
    justify-content: space-between;    
    span{
        font-size: 16px;
        font-weight: 500;    
        color:#999;
    }
}

.taxa-entrega{
    display: flex;
    justify-content: space-between;
    span{
        font-size: 16px;
        font-weight: 500;   
        color:#999;
    }
}

.total{
    display: flex;
    justify-content: space-between;
    span{
        font-size: 18px;
        font-weight: 700;
    }
}

.agreement{
    padding: 10px 16px 10px 16px;
    display: flex;
    justify-content: center;
    label{
        font-size: 11px;
        text-align: center;
    }
}

.selectFinalizarPedidoDisabled{
    button{
        background-color: #eee;
        justify-content: center;
        color:#777;
    }   
}

.selectFinalizarPedidoEnabled{
    button{
        background-color: #1095F3;
        justify-content: center;
        color:white;
    }   
}

.limparAllItensCarrinho{
    color: red;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.btnBlockNotEnabled{
    background-color: #eee;
    color:#777;
    cursor: not-allowed;
}

.loadingMobile{
    top: 41%!important;
    left: 39%!important;
}

.obsContainerFinalizarPedido{
    padding-bottom: 0px!important;
}

.checkout-form .delivery-info,
.checkout-form .delivery-method,
.checkout-form .payment-method,
.checkout-form .observations,
.checkout-form .summary,
.checkout-form .agreement {
    margin-bottom: 12px;
}

.checkout-form .observations textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 8px;
}

.checkout-form .summary {
    border-top: 1px solid #eee;
    padding-top: 16px;
}

.checkout-form .summary .subtotal,
.checkout-form .summary .total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.checkout-form .agreement {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.checkout-form .agreement input {
    margin-right: 8px;
}

.checkout-form .submit-button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
}

@media (prefers-color-scheme: white) {
.checkout-form {
    background-color: #fff;
    color: #333;
}

.address .addAddressButton {
    width: 100%;
    justify-content: center;
    background: #1095F3;
    color: white;
    font-weight: 600;
    font-size: 14px;
    height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.address{
    display: flex;
    p{
        margin: 0px!important;
    }
}

.endEntregaMap{
    display: flex;
    flex-direction: column;
    line-height: 1;
}

.checkout-form .observations textarea {
    background-color: #555;
    color: #fff;
    border-color: #777;
}

.checkout-form .summary {
    border-top-color: #555;
}
}

@media (max-width: 768px) {
.checkout-form {
    padding: 8px;
}
}

.divAddEnderecoPadding{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.divBtnUseLocation{
    border: 2px solid #1095F3;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    min-height: 40px;
    color: #1095F3;
    margin-top: 10px;
    svg{
        font-size: 14px;
    }
    span{
        font-size: 14px;
        font-weight: 500;
    }
}