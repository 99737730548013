/* Google Fonts - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  --font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

@supports (scrollbar-color: #999 #f5f5f5) {
  * {
    scrollbar-color: #999 #f5f5f5;
    scrollbar-width: thin;
  }
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.212);
}

.boxLeftMenuScroll {
  scrollbar-color: auto;
  scrollbar-width: auto;
}

/* Estiliza a barra de rolagem */
.boxLeftMenuScroll::-webkit-scrollbar {
  width: 7px;
  /* Largura da barra de rolagem */
}

/* Estiliza a parte interna da barra de rolagem */
.boxLeftMenuScroll::-webkit-scrollbar-track {
  background: #f0f0f0;
  /* Cor do fundo da barra de rolagem */
  border-radius: 10px;
  /* Cantos arredondados */
}

/* Estiliza a "thumb" da barra de rolagem */
.boxLeftMenuScroll::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  /* Cor da "thumb" */
  border-radius: 10px;
  /* Cantos arredondados */
}

/* Cor ao passar o mouse sobre a "thumb" */
.boxLeftMenuScroll::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
  /* Cor mais escura ao passar o mouse */
}

/* Remove as setas */
.boxLeftMenuScroll::-webkit-scrollbar-button {
  display: none;
  /* Remove as setas padrão */
}

/*
nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
}*/
.info-licenca {
  background: rgba(255, 0, 0, 1);
  --background: linear-gradient(to left, #4281ff, #51d2ff);
  --left: 250px;
  -webkit-transition: 150ms;
  transition: 150ms;
  /* padding-right: 250px; */
  height: 50px;
  --width: calc(100% - 250px);
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  --justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  --border-bottom: 2px solid #0000001c;
  position: relative;
  z-index: 1;
  /* box-shadow: 1px 1px 6px rgb(180, 180, 180); */
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

nav .logo {
  display: flex;
  align-items: center;
  margin: 0 24px;
}

.logo .menu-icon {
  color: #333;
  font-size: 24px;
  margin-right: 14px;
  cursor: pointer;
}

.logo .logo-name {
  color: #333;
  font-size: 22px;
  font-weight: 500;
}

nav .sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100%;
  width: 17%;
  padding: 20px 0;
  --background-color: #fff;
  background-color: rgb(247, 247, 247) !important;
  box-shadow: 0 5px 1px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease;
}

nav.open .sidebar {
  left: 0;
}

.sidebar .sidebar-content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 16px;
}

.sidebar-content .list {
  list-style: none;
}

.list .nav-link {
  --display: flex;
  display: list-item;
  align-items: center;
  margin: 8px 0;
  padding: 14px 12px;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
}

.lists .nav-link:hover {
  background: linear-gradient(to left, #2d74ff, #07bfff);
}

.nav-link .icon {
  margin-right: 14px;
  font-size: 20px;
  color: #707070;
}

.nav-link .link {
  font-size: 16px;
  color: #707070;
  font-weight: 400;
}

.lists .nav-link:hover .icon,
.lists .nav-link:hover .link {
  color: #fff;
}

.overlay {
  position: fixed;
  top: 0;
  left: -100%;
  height: 1000vh;
  width: 200%;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease;
  background: rgba(0, 0, 0, 0.3);
}

nav.open~.overlay {
  opacity: 1;
  left: 260px;
  pointer-events: auto;
}

.subMenu {
  top: -39px;
  --background: transparent !important;
}

.menu-anchor {
  background: transparent !important;
}

.test:hover {
  background: #fff !important;
}

.sub-menu-content {
  background: transparent !important;
}

.menu-item.sub-menu {
  height: 50px;
}

.menu-item.sub-menu.open {
  height: 150px;
}

.collapseDiv {
  display: flex;
  justify-content: right;
}

.userCircleImg {
  display: flex;
  justify-content: center;
}

.userImg {
  border-radius: 100%;
  padding: 0px;
  border: solid 3px #4281ff;
}

.menu-dropdownShow {
  position: absolute;
  background: white;
  display: flex;
  top: 84px;
  border: 1px solid #e1e1e1;
  width: 218px;
}

.menu-dropdownClosed {
  position: relative;
  left: 169px;
  background: white;
  display: none;
  top: 124px;
  border: 1px solid #e1e1e1;
  width: 218px;
}

.divStatusPrintNavBar {
  display: flex;
  justify-content: center;
  background: #07c670;
  color: white;
  padding: 1px;
  border-radius: 4px;

  span {
    border: 1px solid white;
    padding: 0px 4px 0px 4px;
    border-radius: 4px;
    font-size: 12px;
    width: 100%;
    text-align: center;
  }
}

.divStatusPrintNavBarOffline {
  display: flex;
  justify-content: center;
  background: #ff0000b5;
  color: white;
  padding: 1px;
  border-radius: 4px;

  span {
    border: 1px solid white;
    padding: 0px 4px 0px 4px;
    border-radius: 4px;
    font-size: 12px;
    width: 100%;
    text-align: center;
  }
}

.option.bottom {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  padding: 8px;
  height: 40px;
  border-bottom: 1px solid lightgray;
}

.option.bottom-last {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  padding: 8px;
  height: 40px;
}

.description {
  padding-left: 5px;
}

/* Switch 1 Specific Styles Start */

.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /*--width: 400px;
	--margin: 50vh auto 0;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
  */
}

.switch_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  --max-width: 200px;
  --min-width: 200px;
  --height: 200px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /*-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;*/
}

.box_1 {
  background: transparent;
}

input[type="checkbox"].switch_1 {
  font-size: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 3.5em;
  height: 1.5em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked {
  background: #0ebeff;
}

input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked:after {
  left: calc(100% - 1.5em);
}

/* Switch 1 Specific Style End */

/* Switch Aceite automatico Start */
.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.switch_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  --max-width: 200px;
  --min-width: 200px;
  --height: 200px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.box_1 {
  background: transparent;
}

input[type="checkbox"].switch_aceiteAutomatico {
  font-size: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 3.5em;
  height: 1.5em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_aceiteAutomatico:checked {
  background: #0ebeff;
}

input[type="checkbox"].switch_aceiteAutomatico:after {
  position: absolute;
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_aceiteAutomatico:checked:after {
  left: calc(100% - 1.5em);
}

/* Switch Aceite automatico End */

.clickNavigateToPlanos {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
  transition: all .2s;
}

.clickNavigateToPlanos:hover {
  color: black;
}

@media (max-width: 1440px) {
  nav .sidebar {
    width: 100%;
    left: -100%;
  }

  nav.open .sidebar {
    left: 0;
  }

  nav .logo {
    margin: 0 16px;
  }

  .overlay {
    left: 100%;
  }

  nav.open~.overlay {
    left: 0;
  }

  div.hidden-mobile {
    display: none !important;
  }
}

@media (max-width: 780px) {

  .hidden-sm-mobile {
    display: none !important;
  }
}


@media (min-width: 880px) {
  .desktop-hidden {
    display: none;
  }
}

.number-box.number-box--active {
  background: #FFF;
  font-size: 12px;
  font-weight: 700;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.item-menu-help-info {
  display: inline-grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 62px;
  height: 100%;
  background: #e1e1e1;
  border-radius: 10px;
  cursor: pointer;
}

.item-menu-help-info-active {
  display: inline-grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 62px;
  height: 100%;
  background: #009AFF;
  border-radius: 10px;
  cursor: pointer;
}

@-webkit-keyframes shaking {
  from {
    -webkit-transform: rotate(15deg)
  }

  to {
    -webkit-transform: rotate(-15deg)
  }
}

@keyframes shaking {
  from {
    transform: rotate(15deg)
  }

  to {
    transform: rotate(-15deg)
  }
}

#shaking {
  -webkit-animation: shaking 0.3s alternate infinite ease-in-out;
  animation: shaking 0.3s alternate infinite ease-in-out;
}

.atendimento-modal {
  position: fixed;
  top: 88px;
  right: 62px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 8px;
  z-index: 1000;
  max-height: 420px;
  overflow-x: hidden;
  overflow-y: scroll;
  max-width: 340px;
  scrollbar-color: auto;
  scrollbar-width: auto;
}

/* Estiliza a barra de rolagem */
.atendimento-modal::-webkit-scrollbar {
  width: 7px;
  /* Largura da barra de rolagem */
}

/* Estiliza a parte interna da barra de rolagem */
.atendimento-modal::-webkit-scrollbar-track {
  background: #f0f0f0;
  /* Cor do fundo da barra de rolagem */
  border-radius: 10px;
  /* Cantos arredondados */
}

/* Estiliza a "thumb" da barra de rolagem */
.atendimento-modal::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  /* Cor da "thumb" */
  border-radius: 10px;
  /* Cantos arredondados */
}

/* Cor ao passar o mouse sobre a "thumb" */
.atendimento-modal::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
  /* Cor mais escura ao passar o mouse */
}

/* Remove as setas */
.atendimento-modal::-webkit-scrollbar-button {
  display: none;
  /* Remove as setas padrão */
}

.atendimento-modal-content h3 {
  margin-top: 0;
  padding-bottom: 10px;
}

.atendimento-modal-content p {
  font-size: 13px;
  margin-bottom: 5px !important;
}

.atendimento-card {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}

.atendimento-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.atendimento-buttons button {
  font-size: 11px;
  max-height: 40px;
}

.atendimento-whatsapp-btn,
.atendimento-resolvido-btn,
.atendimento-fechar-btn {
  border: none;
  padding: 8px;
  cursor: pointer;
  font-weight: bold;
}

.atendimento-whatsapp-btn {
  background: #25d366;
  color: white;
  border-radius: 5px;
}

.atendimento-resolvido-btn {
  background: #007bff;
  color: white;
  border-radius: 5px;
}

.atendimento-fechar-btn {
  display: block;
  width: 100%;
  margin-top: 10px;
  background: red;
  color: white;
  border-radius: 5px;
}

.atendimento-simular-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: blue;
  color: white;
  border: none;
  cursor: pointer;
}

.atendimento-notification {
  background: red;
  color: white;
  padding: 5px;
  border-radius: 50%;
  font-size: 12px;
  margin-left: 5px;
}

.atendimento-teste-btn {
  color: black;
  background-color: #e1e1e1;
  border: 1px solid gray;
  max-width: 120px;
}

.collapseInLeftMenuBtn {
  color: gray;
  right: -8px;
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  width: 30px;
  height: 30px;
  padding: 4px;
  background: white;
  border-radius: 50%;
  border: 1px solid gray;
  transition: all .2s;
}

.collapseOutLeftMenuBtn {
  color: gray;
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  border-radius: 50%;
  right: -8px;
  background: white;
  border: 1px solid gray;
  padding: 4px;
  width: 30px;
  height: 30px;
  transition: all .2s;
}

.collapseInLeftMenuBtn:hover {
  font-size: 25px;
  width: 35px;
  height: 35px;
}

.collapseOutLeftMenuBtn:hover{
  font-size: 25px;
  width: 35px;
  height: 35px;
}