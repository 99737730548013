.forma-pagamento-settings {
  font-family: Arial, sans-serif;
  --max-width: 600px;
  --margin: 0 auto;
}

.formas-pagamento-options {
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    margin-bottom: 35px;
    color: dimgray;
  }
}

.formas-pagamento-item {
  display: flex;
  gap: 10px;

  label {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}