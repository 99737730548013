@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

@media only screen and (max-width: 1366px) and (max-height: 768px) {

    * {
        font-size: 12px;
    }

    .header-column-analise{
        h4{
            font-size: 18px;
            font-weight: 700!important;
        }
    }
    .header-column-producao{
        h4{
            font-size: 18px;
            font-weight: 700!important;
        }
    }
    .header-column-pronto{
        h4{
            font-size: 18px;
            font-weight: 700!important;
        }
    }
    .header-column-finalizado{
        font-size: 10px!important;
        h4{
            font-size: 18px!important;
            font-weight: 700!important;
        }
    }

    
    .m-5 {
        background: rgb(247,247,247)!important;
    }

    *{
        margin:0;
        padding:0;
        box-sizing: border-box;
    }

    .container{
        width: 100%;
        margin: 0 auto;
        
        display: flex;
        flex-flow: row wrap;
    }

    .container-menu{
        width: 50%;
        min-height: 100vh;

        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content: center;

        padding: 15px;

        background-color:#F8FAF9;
    }


    .container-direita{
        width: 50%;
        min-height: 100vh;

        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content: center;

        padding: 15px;

        background-color:#1E32FF;
    }

    .buttons-menu{
        width: 100px;
    }

    .input-box-list.home{
        margin-bottom: 0px!important;
    }

    .input-fieldClienteOuPedido{
        box-shadow: 0px 0px 3px 2px lightgray!important;
    }

    .continue-button.cfgHome button{
        background: white!important;
        width:50px!important;
        --border: 1px solid black!important;
        box-shadow: 0px 0px 3px 2px lightgray;
    }

    .continue-button.cfgHome{
        width:50px!important;
    }

    .blocoAnalise{
        display: table;
        padding: 10px;
        background: #D0D1D1;
        min-height: 770px;
        width: 100%;
        height: 97%;
    }

    .blocoProducao{
        display: table;
        padding: 10px;
        background: #FFA800;
        min-height: 770px;
        width: 100%;
        height: 97%;
    }

    .blocoPronto{
        display: table;
        padding: 10px;
        background: #07C670;
        min-height: 770px;
        width: 100%;
        height: 97%;
    }

    .blocoFinalizado{
        display: table;
        padding: 10px;
        background:#C7FCFF;
        min-height: 770px;
        width: 100%;
        height: 97%;
    }

    .containerAnalise{
        height: 100%;
    }

    .containerProducao{
        height: 100%;
    }

    .containerPronto{
        height: 100%;
    }

    .containerFinalizado{
        height: 100%;
    }

    .header-column-analise{
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        background: #7C7C7C;
        font-weight: bold;
        color: black;
        padding: 6px;
        h4{
            margin-bottom: 0px!important;
            font-weight: 800!important;
        }
    }

    .header-column-producao{
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        background: #8F5F00;
        color: black;
        padding: 6px;
        h4{
            margin-bottom: 0px!important;
            font-weight: 800!important;
        }
    }

    .header-column-pronto{
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        background: #046338;
        color: black;
        padding: 6px;
        h4{
            margin-bottom: 0px!important;
            font-weight: 800!important;
        }
    }


    .header-column-finalizado{
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        background: #00A2AB;
        color: black;
        padding: 6px;
        h4{
            margin-bottom: 0px!important;
            font-weight: 800!important;
        }
    }


    .header-analise{
        display: inline-flex;
        padding: 10px;
    }

    .time{
        background: white;
        border-radius: 8px;
        padding: 10px;
        display: inline-flex;
        width: 100%;
        justify-content: space-between;
        font-size: 12px;
        height: 100px;
    }

    .titleOptEmp{
        margin-bottom: 0.5rem!important;
    }

    .text-edit{
        display: flex;
        align-items: center;
        font-weight: bold;
        text-decoration: underline;    
        transition: .2s;
    }

    .text-edit:hover{
        color: rgb(49, 140, 213);
    }

    .text{
        padding:40px; 
        font-size: 14px;    
        font-weight: 500;
        p{
            margin-bottom:0px; 
            justify-content:center; 
            text-align:center; 
            display:flex;
        }                             
    }

    .header-column-analise{
        padding-left: 5px;
        h3{
            padding-right: 5px;
        }
    }

    .header-column-producao{
        padding-left: 5px;
        h3{
            padding-right: 5px;
        }
    }

    .header-column-pronto{
        padding-left: 5px;
        h3{
            padding-right: 5px;
        }
    }


    .input-box.number{
        display: inline-block!important;
        --width: 5%!important;
        input{
            padding: 0.8rem 0px 10px 0px!important;
            text-align: center;
            min-width: 60%;
        }
    }

    .decrement{    
        border:none;
        width: 36px;
        height: 36px;
        border-radius: 10px;
        background: white;
        box-shadow: 0px 0px 1px 1px lightgray;
        margin-right: 5px;
        svg{
            color:#4a4a4a;
        }
    }

    .decrement.disable{    
        border:none;
        width: 36px;
        height: 36px;
        border-radius: 10px;
        box-shadow: 0px 0px 1px 1px lightgray;
        margin-right: 5px;
        pointer-events: none;
        background-color: #ccc;
        cursor: not-allowed;
        opacity: 0.5;
        svg{
            color:#4a4a4a;
        }
    }


    .increment{    
        border:none;
        width: 36px;
        height: 36px;
        border-radius: 10px;
        background: white;
        box-shadow: 0px 0px 1px 1px lightgray;
        margin-left: 5px;
        svg{
            color:#4a4a4a;
        }
    }

    .line{
        height: 2px;
        width: 100%;
        background: lightgray;
        margin-left: 5px;
    }

    .subtitle-form-tempo-entrega{
        color:gray;
        font-weight:bold;
        display: flex;
        align-items: center;
        --width: 280px;
    }

    .configure-tempo-entrega{
        display: inline-grid;
        width: 50%;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    .bloco-pedidos{
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 7px;
        margin-top: 5px;
        padding: 5px 5px 5px 5px;
        border: 1px solid #ffffff;
        cursor: pointer;
        transition: .1s;
    }

    .bloco-pedidos-printing{
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 10px 10px;
        border: 1px solid #ffffff;
        cursor: pointer;
        transition: .1s;
    }

    .roboto-font *{
        font-family: "Roboto Condensed", sans-serif!important;
        font-weight: 700;
        font-style: normal;
    }

    .bloco-pedidos:hover{
        box-shadow: 5px 5px 9px -2px #6a6a6a;
    }

    .pedido-time{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .tag-pedido{
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .icon-pedido{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f5f5;
        border-radius: 5px;
        height: 30px;
        width: 30px;
        flex-shrink: 0;
    }

    .time-container{
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .time-div{
        display: flex;
        align-items: center;
        background-color: #d3eeff;
        color: #0067ac;
        border-radius: 3px;
        padding: 3px 5px;
        gap: 3px;
        height: 22px;
        box-sizing: border-box;
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
    }

    .cliente-total{
        margin-top: 5px;
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .bloco{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .linha{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .texto{
        color: #5a5a5a;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    .label-recorrencia-pedido{
        background-color: #5aaf51;
        border-color: #5aaf51;
        border-radius: 4px;
        width: 15px;
        height: 15px;
    }

    .caixaNumero{
        color:white;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .bold{
        color: #2b2b2b;
        text-align: right;
        font-size: 13px;
        font-weight: 700;
        line-height: 19px;
    }

    .bloco-entrega{
        margin-top: 5px;
        border-radius: 4px;
        background-color: #f5f5f5;
    }

    .flex-entrega{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        padding: 5px;
    }

    .bloco-footer{
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 5px;
        min-width: 70%;
    }

    .pin{
        width: 12px;
        height: 12px;
        stroke: rgb(157, 157, 157);
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    .underline{
        font-style: italic;
        font-weight: 500;
        text-decoration-line: underline;
    }


    .button-avancar-pedido button {
        border: none;
        box-shadow: 1px 1px 6px lightgray;
        height:40px;
        background:white;
        border:solid 2px var(--main-color-pedeja);
        width:100%;
        background: -webkit-linear-gradient(to left, #2D74FF, #07BFFF);
        background: -o-linear-gradient(to left, #2D74FF, #07BFFF);
        background: -moz-linear-gradient(to left, #2D74FF, #07BFFF);
        background: linear-gradient(to left, #2D74FF, #07BFFF);
        background: white!important;
        padding: 0.62rem;
        border-radius: 5px;
        align-items: center;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .button-avancar-pedido button:hover {
        background: -webkit-linear-gradient(to left, #4281FF, #51D2FF);
        background: -o-linear-gradient(to left, #4281FF, #51D2FF);
        background: -moz-linear-gradient(to left, #4281FF, #51D2FF);
        background: linear-gradient(to left, #4281FF, #51D2FF)!important;
        border:none;
        a{
            color:white!important;
        }
        svg{
            color: white!important;
        }
    }

    .button-avancar-pedido button a {
        text-decoration: none;
        font-size: 12px;
        font-weight: 700;
        color: var(--main-color-pedeja)!important;
    }

    .button-avancar-pedido button svg {
        color: var(--main-color-pedeja)!important;
    }

    .justifycontent-center{
        display: flex;
        justify-content: center;
        font-weight: bold;
        font-size: 22px!important;
    }

    .justifycontent-center.numero-pedido{
        display: flex;
        justify-content: center;
        font-weight: bold;
        font-size: 46px!important;
    }


    .itens-justify{
        display: flex;
        justify-content: space-between;
        padding: 5px;
        font-weight: 600;
    }

    .itens-justify-valores{    
        display: flex;
        justify-content: space-between;
        padding: 0px 5px 0px 5px;
        font-weight: 600;
    }

    .itens-row-cliente{
        padding: 5px;
        font-weight: 600;
        padding-bottom: 20px;
    }

}