@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    --font-family: 'Open Sans', sans-serif;
    font-family: "Poppins", sans-serif;
}

.container {
    width: 100%;
    height: 100vh;
    display: flex;
    --box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.212);
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    --background-color: #fff;
    background-color:rgb(247,247,247)!important;
    padding: 3rem;
    padding-left: 8rem;
}

.form-header {
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
}

button{
    color: white;
}

.login-button {
    display: flex;
    align-items: center;
}

.login-button button {
    border: none;
    background: linear-gradient(to left, #2D74FF, #07BFFF);
    padding: 0.4rem 1rem;
    border-radius: 5px;
    cursor: pointer;
}

.login-button button:hover {
    background: linear-gradient(to left, #2D74FF, #07BFFF);
}

.login-button button a {
    text-decoration: none;
    font-weight: 500;
    color: #fff;
}

.form-header h1::after {
    content: '';
    display: block;
    width: 5rem;
    height: 0.3rem;
    --background-color: #4070f4;
    background: linear-gradient(to left, #2D74FF, #07BFFF);
    margin: 0 auto;
    position: absolute;
    border-radius: 10px;
}

.input-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    --padding: 1rem 0;

    background: white;
    padding: 20px!important;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 6px lightgray;
}

.input-group-edit {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 0;
}

.input-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.1rem;
}

.input-box input {
    margin: 0.6rem 0;
    padding: 0.8rem 2.5rem;
    border: none;
    border-radius: 10px;
    box-shadow: 1px 1px 6px lightgray;
    font-size: 0.8rem;
}

/*.css-13cymwt-control{
    border: none!important;
    border-radius: 10px!important;
    box-shadow: 1px 1px 6px lightgray!important;
    font-size: 0.8rem!important;
    padding: 0.8rem 2.5rem!important;
    margin: 0.6rem 0!important;
}*/

.input-box input:hover {
    background-color: #eeeeee75;
}

.input-box input:focus-visible {
    outline: 1px solid #4070f4;
}

.asyncSelectInput{
    input{        
        box-shadow: none!important;
    }
}

.input-box label,
.gender-title h6 {
    font-size: 0.75rem;
    font-weight: 600;
    color: #000000c0;
}

.input-box input::placeholder {
    color: #000000be;
}

.gender-group {
    display: flex;
    justify-content: space-between;
    margin-top: 0.62rem;
    padding: 0 .5rem;
}

.gender-input {
    display: flex;
    align-items: center;
}

.gender-input input {
    margin-right: 0.35rem;
}

.gender-input label {
    font-size: 0.81rem;
    font-weight: 600;
    color: #000000c0;
}

.continue-button{
    text-align: center;
}

.continue-button button {
    width: 30%;
    margin-top: 2.5rem;
    border: none;
    
    --background: -webkit-linear-gradient(to left, #2D74FF, #07BFFF);
    --background: -o-linear-gradient(to left, #2D74FF, #07BFFF);
    --background: -moz-linear-gradient(to left, #2D74FF, #07BFFF);
    --background: linear-gradient(to left, #2D74FF, #07BFFF);
    background: #0c9abe;

    padding: 0.62rem;
    border-radius: 5px;
    cursor: pointer;
}

.continue-button button:hover {
    --background: -webkit-linear-gradient(to left, #4281FF, #51D2FF);
    --background: -o-linear-gradient(to left, #4281FF, #51D2FF);
    --background: -moz-linear-gradient(to left, #4281FF, #51D2FF);
    --background: linear-gradient(to left, #4281FF, #51D2FF);
    background: #3caecb;
}

.continue-button button a {
    text-decoration: none;
    font-size: 0.93rem;
    font-weight: 500;
    color: #fff;
}

@media screen and (max-width: 1330px) {
    .form-image {
        display: none;
    }
    .container {
        width: 50%;
    }
    .form {
        width: 100%;
    }
}

@media screen and (max-width: 1064px) {
    .container {
        width: 90%;
        height: auto;
    }
    .input-group {
        flex-direction: column;
        z-index: 5;
        padding-right: 5rem;
        max-height: 10rem;
        overflow-y: scroll;
        flex-wrap: nowrap;
    }
    .gender-inputs {
        margin-top: 2rem;
    }
    .gender-group {
        flex-direction: column;
    }
    .gender-title h6 {
        margin: 0;
    }
    .gender-input {
        margin-top: 0.5rem;
    }
}


.error{
    color:red;
    font-size:12px;
    margin-left: 40px;
}




/* Combobox */
.selectdiv {
    position: relative;
    /*Don't really need this just for demo styling*/
    
    float: left;
    min-width: 20%;
    --margin: 50px 33%;
  }
  
  /* IE11 hide native button (thanks Matt!) */
  select::-ms-expand {
  display: none;
  }
  
  .selectdiv:after {
    content: '<>';
    font: 14px "Consolas", monospace;
    color: #333;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 11px;
    /*Adjust for position however you want*/
    
    top: 18px;
    padding: 0 0 2px;
    border-bottom: 1px solid #999;
    /*left line */
    
    position: absolute;
    pointer-events: none;
  }
  
    .invalid-option {
        color: gray!important;
    }
    .invalid-option:hover {
        background: #eeeeee75!important;
    }
    .beleza:hover {
        background: #eeeeee75!important;
    }


  .selectdiv select {
    
    margin: 0.6rem 0;
    padding: 0.8rem 2.5rem;
    border: none;
    border-radius: 10px;
    box-shadow: 1px 1px 6px lightgray;
    font-size: 0.8rem;
    
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /*  Add some styling */
    
    display: block;
    width: 100%;
    max-width: 100%!important;
    height: 45px;
    float: right;
    margin: 5px 0px;
    padding: 0px 24px;
    font-size: 0.8rem;
    line-height: 1.75;
    color: #333;
    background-color: #ffffff;
    background-image: none;
    --border: 1px solid #cccccc;
    -ms-word-break: normal;
    word-break: normal;
    margin-top: 10px;
    
  }

  /* Combobox */


  