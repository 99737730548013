
.box-content-pedido{
    overflow-y: auto;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #2b2b2b;
}

.header-pedido{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-header-pedido{
    display: flex;
    align-items: center;
    gap: 10px;
}

.buttonsTitle{
    gap: 5px;
}

.info-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-box{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgray;
    border-radius: 5px;
    height: 35px;
    width: 35px;
    flex-shrink: 0
}

.title{
    color: #5a5a5a;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
}

.status-order{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    padding: 2px 4px;
    color: #fff;
}

.status-order.analise{
    background-color: #D0D1D1;
}

.status-order.producao{
    background-color: #FFA800;
}

.status-order.pronto{
    background-color: #07C670;
}

.status-order.finalizado{
    background-color: #C7FCFF;
    color: black;
}

.time-OnModalPedido{
    display: flex;
    align-items: center;
    background-color: #d3eeff;
    color: #0067ac;
    border-radius: 3px;
    padding: 5px 8px;
    gap: 3px;
    height: 30px;
    box-sizing: border-box;
    width: fit-content;
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
}

.icon-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #9D9D9D;
    border-radius: 5px;
    color: #9d9d9d;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.icon-btn.avancar{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(49, 140, 213);
    border-radius: 5px;
    color: rgb(49, 140, 213);
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.icon-btn.trash:hover{
    background-color: #ff4c4c;
    color:white;
    border:none;
}

.icon-btn.edit-print:hover{
    background-color: rgb(49, 140, 213);
    color:white;
    border:none;
}

.icon-btn.avancar:hover{
    background-color: rgb(49, 140, 213);
    color:white;
    border:none;
}

.closeModalPedido{
    cursor: pointer;
    transition: .2s;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -10px;
    right: -10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 100%;
    padding: 3px;
    transform: rotate(0deg);
    transition: transform 0.2s linear,
                transform 0.2s linear,
                width 0.2s linear,
                height 0.2s linear;
}


.closeModalPedido:hover{
    transition: .2s;
    transform: rotate(90deg) scale(1.2);
    width: 28px!important;
    height: 28px!important;
    border: 1px solid gray;
}
  

.bodyModalPedido{
    margin-top: 10px;
    display: flex;
}

.bodyLeft{
    padding-right: 30px;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
}

.bodyRight{
    border: solid 1px #EBEBEB;
    border-radius: 4px;
    justify-content: space-between;
    gap: 0px;
    height: 100%;
    width: 100%;
    max-width: 500px;
}

.coluna-cliente{
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.cliente{
    color: #5a5a5a;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
}

.cliente-nome{
    display: flex;
    align-items: center;
    gap: 3px;
    color: #5a5a5a;
    font-size: 13px;
    font-weight: 400;
    width: fit-content;
}

.cliente-telefone{
    color: rgb(49, 140, 213);
    font-size: 13px;
    font-weight: 700;
    line-height: 19px;
    text-decoration-line: underline;
    cursor: pointer;
}

.cliente-numPedidos{
    display: flex;
    align-items: center;
    gap: 3px;
    color: #5a5a5a;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    width: fit-content;
}

.sendToWhatsApp{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    color: #5a5a5a;
    cursor: pointer;
}

.sendToWhatsApp:hover{
    background-color: rgb(49, 140, 213);
    color: white;
}

.pedido-info-title{
    padding: 8px 10px;
    background-color: #ebebeb;
    font-size: 13px;
    font-weight: 700;
    line-height: 19px;
    color: #5a5a5a;
}

.itens-pedido-body{
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 400px;
    max-height: 500px;
}

.container-resumo-pedido{
    border: solid 1px #EBEBEB;
    border-radius: 4px 4px 0px 0px;
    justify-content: space-between;
    gap: 0px;
    height: 100%;
    width: 100%;
    max-width: 500px;
}

.arrayPedidos{
    overflow-y: auto;
    width: 100%;
    padding: 10px 15px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #2b2b2b;
    border-bottom: 1px solid lightgray;
}

.info-pedidos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
}

.info-pedidos-title{
    padding: 2px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
}

.info-adicionais-title{
    padding: 1px 0px 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    font-size: 13px;
    font-weight: 500;
}

.obsItemModalPedido{
    margin-top: 4px;
    display: flex;
    border: 1px dashed #aaaa;
    padding: 2px 7px;
    border-radius: 10px;
}

.box-adicionais-modal-item{
    display: flex;
    flex-direction: column;
    padding: 5px 0px 0px 10px;
}

.preco-item{
    color: #5a5a5a;
    font-size: 14px;
    font-weight: 400;
}

.subTotal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 700;
    line-height: 19px;
    color: #5a5a5a;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top:15px;
}

.rodape-total{
    display: flex;
    flex-direction: column;
    background-color: #ebebeb;
    gap: 5px;
    padding: 8px 10px;
}

.rodape-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}