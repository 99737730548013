.mapContainer{
    height: 100%;
}

.mapMarker{
    margin-top: -30px;
    font-weight: 600;
}

.custom-map{
    button{
        color:#000;
    }
}

.divContainerAddItem.Mapa{
    width: 100%;
}

.precoEntrega{
    display: flex!important;
}

.input-box.number.precoRaioEntrega{
    width: 35%!important;
}

.grupoKmAndPrice{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    gap: 10px;
}

.input-box.precoItemCustom.paraEntrega{
    width:35%!important;
}

.divAddRaioEntrega{
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonAddRaioEntrega{
    background: rgb(49, 140, 213);
    border-radius: 5px;
    height: 30px;
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    margin-left: 40px;
    cursor: pointer;
    span{
        font-size: 15px;
        font-weight: 500;
        color:white;
        padding:5px 10px;
    }
}

.scrollRaiosContainer{
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
}

.tableQtdRegHeader{
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.tableQtdRegHeaderRaio{
    width: 50%;
    font-size: 13px;
    font-weight: 700;
}

.tableQtdRegHeaderValor{
    width: 50%;
    font-size: 13px;
    font-weight: 700;
}

.tableQtdRegBody{
    overflow: auto;
    max-height: 500px;
}

.tableQtdRegBody.even {
    background-color: rgb(240,240,240);
}

.tableQtdRegBody.odd {
    background-color: white;
}

.tableQtdRegRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.qtdRegTableRowRaioAndValor{
    display: flex;
    flex-direction: row;
    width: 95%;
}

.qtdRegTableRowRaio{
    padding-left: 20px;
    width: 51%;
    align-items: end;
    display: flex;
}

.qtdRegTableRowValor{
    --padding-left: 75px;
    width: 45%;
    align-items: start;
    justify-content: flex-start;
    display: flex;
}

.btnsQtdRegTable{
    gap: 10px;
    display: flex;
    align-items: center;
}

.inputRaioPersonalizado{
    margin: 0.6rem 0 4px 0!important;
}

.toogleRaioContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

.divToogleRaio{
    height: 40px;
    display: flex;
    width: max-content;
    border-radius: 5px;
    overflow: hidden;
    gap: 1px;
    background-color: #c4c4c4;
    border: 1px solid #C4C4C4;
}

.btnToogleBairro{
    button{
        width:100px;
        padding: 20px;
        cursor: pointer;
        transition: .2s;
        border: none;
        height: 100%;
        color: #5a5a5a;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        position: relative;
    }
}

.btnToogleRaio{
    button{
        width:100px;
        padding: 20px;
        cursor: pointer;
        transition: .2s;
        border: none;
        height: 100%;
        color: #5a5a5a;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        position: relative;
    }
}

.divContainerAddItem.Bairro{
    width: 40%;
}