:root {
    --main-color-pedeja: rgb(49, 140, 213);
    ;
}

.m-5 {
    background: rgb(247, 247, 247) !important;
}

.m-5.pdv {
    display: inline-flex;
    height: 85vh;
    margin-top: 40px !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.container {
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-flow: row wrap;
}

.container-menu {
    width: 50%;
    min-height: 100vh;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    padding: 15px;

    background-color: #F8FAF9;
}


.container-direita {
    width: 50%;
    min-height: 100vh;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    padding: 15px;

    background-color: #1E32FF;
}

.buttons-menu {
    width: 100px;
}

.input-box-list.home {
    margin-bottom: 0px !important;
}

.input-fieldClienteOuPedido {
    box-shadow: 0px 0px 3px 2px lightgray !important;
}

.continue-button.cfgHome button {
    background: white !important;
    width: 50px !important;
    --border: 1px solid black !important;
    box-shadow: 0px 0px 3px 2px lightgray;
}

.continue-button.cfgHome {
    width: 50px !important;
}


@-webkit-keyframes shaking {
    from {
        -webkit-transform: rotate(15deg)
    }

    to {
        -webkit-transform: rotate(-15deg)
    }
}

@keyframes shaking {
    from {
        transform: rotate(15deg)
    }

    to {
        transform: rotate(-15deg)
    }
}

#shaking {
    -webkit-animation: shaking 0.3s alternate infinite ease-in-out;
    animation: shaking 0.3s alternate infinite ease-in-out;
}

.input-box.number {
    display: inline-block !important;
    --width: 5% !important;

    input {
        padding: 0.8rem 0px 10px 0px !important;
        text-align: center;
        min-width: 60%;
    }
}

.decrement {
    border: none;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    background: white;
    box-shadow: 0px 0px 1px 1px lightgray;
    margin-right: 5px;

    svg {
        color: #4a4a4a;
    }
}

.decrement.disable {
    border: none;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    box-shadow: 0px 0px 1px 1px lightgray;
    margin-right: 5px;
    pointer-events: none;
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.5;

    svg {
        color: #4a4a4a;
    }
}


.increment {
    border: none;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    background: white;
    box-shadow: 0px 0px 1px 1px lightgray;
    margin-left: 5px;

    svg {
        color: #4a4a4a;
    }
}

.line {
    height: 2px;
    width: 100%;
    background: lightgray;
    margin-left: 5px;
}

.same-category {
    padding: 5px 0px 10px 0px;
}

.line-separator {
    height: 1px;
    width: 100%;
    background: lightgray;
    --margin-left: 0px;
}

.subtitle-form-tempo-entrega {
    color: gray;
    font-weight: bold;
    display: flex;
    align-items: center;
    --width: 280px;
}

.configure-tempo-entrega {
    display: inline-grid;
    width: 50%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.itens-cardapio {
    width: 70%;
}

.gerar-pedido {
    width: 100%;
    height: fit-content;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 1px 1px lightgray;
    margin-top: 1px;
    margin-right: 5px;
}

@media (max-width:1400px) {
    .gerar-pedido {
        --height: 87vh !important;
    }
}

@media (max-width:1300px) {
    .container-cliente-pedido {
        height: 32px !important;
    }
}

.grid-cardapio {
    background: white;
    border-radius: 10px;
    --height: 98%;
    box-shadow: 0px 0px 1px 1px lightgray;
    margin-bottom: 5px;
}

@media (max-width: 1400px) {
    .grid-cardapio {
        --height: 95%;
    }
}

@media (max-width: 1300px) {
    .grid-cardapio {
        --height: 91%;
    }
}

@media (max-width: 1300px) {
    button {
        height: 30px;
    }

    div[type="button"] {
        margin-top: 8px;
    }
}

@media (max-width: 1300px) {
    .buttons-edit-delete-obs {
        button {
            height: 35px !important;
            width: 90px !important;
        }
    }
}

@media (max-width: 1300px) {
    .esc {
        margin-top: 5px;

    }
}

.cardapio-filtro {
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
    height: 8%;
    justify-content: end;

    span {
        font-weight: bold;
        color: rgb(49, 140, 213);
        cursor: pointer;
        margin-left: 20px;
    }
}

.textMandatoryQtd {
    font-size: 13px;
    margin-left: 3px;
    font-weight: 500;
    text-decoration: underline;
}

.cardapio-avancar {
    border-top: 1px solid lightgray;
    height: 6%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.center-cardapio {
    height: calc(100vh - 240px);
    display: inline-flex;
    width: 100%;
}

.categoria-cardapio {
    width: 20%;
    min-width: 85px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    --height: calc(100vh - 240px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 3px 10px 3px;
    position: relative;
    scroll-behavior: smooth;
    border-right: 1px solid #e1e1e1;
}

.divObsDoPedidoPDV {
    display: flex;
    gap: 5px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    padding-left: 15px;
    padding-top: 10px;
}

.categoria-item {
    width: 100%;
    min-height: 50px;
    max-height: 50px;
    border-radius: 5px;
    border: 1px solid #D7D8DD;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .2s;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    padding: 2px;
}

.pai {
    position: relative;
    transition: all .3s;
    --padding-bottom: 30px;
    /* Ajuste conforme necessário para evitar corte */
    --padding-bottom: 30px;
    /* Ajuste conforme necessário para evitar corte */
}

.divBorderBottomPaymentDividido {
    border-bottom: 1px solid #ddd;
}

.divBorderBottomPaymentDividido:last-child {
    border-bottom: none;
}

.btnEditModalPaymentDividido,
.btnRemoveModalPaymentDividido {
    width: 100%;
    height: 30px;
    justify-content: center;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    font-size: 12px;
    padding: 0px 10px;
    background: transparent;
    border: none;
    color: gray;
}

.btnEditModalPaymentDividido:hover,
.btnRemoveModalPaymentDividido:hover {
    transition: all .3s;
}

.btnEditModalPaymentDividido:hover {
    background: rgb(32, 82, 190);
    color: white;
}

.btnRemoveModalPaymentDividido:hover {
    background: orangered;
    color: white;
}

.btnAddObsItemRelative,
.btnRemoveItemRelative {
    width: 100%;
    justify-content: center;
    opacity: 0;
    display: none;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    visibility: hidden;
    font-size: 12px;
    padding: 10px;
    background: transparent;
    border: none;
}

.btnAddObsItemRelative:hover,
.btnRemoveItemRelative:hover {
    transition: all .3s;
}

.btnRemoveItemRelative:hover {
    background: orangered;
}

.btnAddObsItemRelative:hover {
    background: rgb(32, 82, 190);
}

.pai:hover .btnAddObsItemRelative,
.pai:hover .btnRemoveItemRelative,
.btnAddObsItemRelative:hover,
.btnRemoveItemRelative:hover {
    opacity: 1;
    display: flex;
    visibility: visible;
}

.pai:hover {
    background: rgb(49, 140, 213);
    transition: all .3s;
    color: white;
    --padding-bottom: 30px;
    /* Ajuste conforme necessário para evitar corte */
}

.clientes-list {
    border: 1px solid #ccc;
    max-height: 130px;
    overflow-y: auto;
    position: absolute;
    background: white;
    width: 100%;
    top: 38px;
    left: 0px;
    z-index: 1000;
    transition: max-height 0.3s ease-in-out;
}

.clientes-list.hidden {
    max-height: 0;
    overflow: hidden;
}

.clientes-list.visible {
    max-height: 130px;
}

@keyframes growDown {
    0% {
        max-height: 0;
    }

    100% {
        max-height: 130px;
    }
}

.clientes-list.visible {
    animation: growDown 0.3s ease-in-out forwards;
}

.cliente-item {
    padding: 8px;
    cursor: pointer;
}

.cliente-item:hover {
    background-color: #f0f0f0;
}

.emptyCartDivInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 10px 50px;

    span {
        font-size: 12px;
        text-align: center;
    }
}

.divSelectFormaEntregaModal {
    display: flex;
    width: 100%;
}

.divModalEntregaPDV {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.divFormaEntrega_1 {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #999;
    border-radius: 6px 0px 0px 6px;
    min-height: 40px;
    transition: all .3s;
    cursor: pointer;
}

.divFormaPagamento_meio {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #999;
    min-height: 40px;
    transition: all .3s;
    cursor: pointer;
}

.divBtnAddFormaEntregaModal {
    width: 100%;
    min-height: 40px;
    font-size: 14px;
    outline: none;
    border-radius: 4px;
    font-weight: 700;
    user-select: none;
    cursor: pointer;
    transition: all .2s;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: #1095F3;
    color: #FFF !important;

    button {
        width: 100%;
        font-size: 14px;
        border: none;
        outline: none;
        border-radius: 4px;
        font-weight: 700;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        transition: all .2s;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        background-color: #1095F3;
        color: #FFF !important;
    }
}

.divFormaEntrega_2 {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #999;
    border-radius: 0px 6px 6px 0px;
    min-height: 40px;
    transition: all .3s;
    cursor: pointer;
}

.divFormaEntrega_1:hover,
.divFormaEntrega_2:hover,
.divFormaPagamento_meio:hover {
    background: #7bc2f5;
    color: white;
    transition: all .3s;
}

.divBtnCadastroEndModal {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;

    button {
        width: 100%;
        font-size: 14px;
        border: none;
        outline: none;
        border-radius: 4px;
        font-weight: 700;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        transition: all .2s;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        background-color: #1095F3;
        color: #FFF !important;
        padding: 8px 10px;
    }

    button:hover {
        background: #0D6EFD;
    }
}

.divEnderecosEntregaModal {
    border: 1px solid #999;
    border-radius: 6px;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    max-height: 300px;
}

.btnEditAddressModalEntrega {
    font-size: 12px;
    color: rgb(16, 149, 243);
    font-weight: 700;
    cursor: pointer;
    background: white;
    border: 1px solid rgb(16, 149, 243);
    border-radius: 3px;
    padding: 0px 5px;
}

.btnEditAddressModalEntrega:hover {
    background: rgb(16, 149, 243);
    color: white;
    border: 1px solid white;
    transition: all .3s;
}

.divBtnModalPagLancarDividido {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;

    button {
        height: 40px;
        width: 100%;
        border: 1px solid #1095F3;
        background: white;
        color: #1095F3;
        border-radius: 8px;
        transition: all .3s;
    }

    button:hover {
        border: 1px solid #1095F3;
        color: white;
        background: #1095F3;
        transition: all .3s;
    }
}

.btnDeleteAddressModalEntrega {
    font-size: 12px;
    color: red;
    font-weight: 700;
    cursor: pointer;
    background: white;
    border: 1px solid red;
    border-radius: 3px;
    padding: 0px 5px;
}

.btnDeleteAddressModalEntrega:hover {
    background: red;
    color: white;
    transition: all .3s;
}

.calculandoDistanciaModalEntrega {
    fill: #333 !important;
    width: 64px !important;
    height: 64px !important;
    position: absolute;
    top: 41%;
    left: 41%;
}

.divBtnSaveCancelModalEntrega {
    display: flex;
    width: 100%;
    gap: 15px;
    margin-top: 8px;

    button {
        height: 40px;
        width: 50%;
        border: 1px solid #aaa;
        background: white;
        color: #aaa;
        border-radius: 8px;
    }

    button:hover {
        border: 1px solid #1095F3;
        color: #1095F3;
    }
}

.divEnderecoEntregaMap {
    white-space: normal;
    /* Permite a quebra de linha */
    word-wrap: break-word;
    /* Quebra a palavra se for muito longa */
    --word-break: break-all;
    /* Quebra a palavra em qualquer ponto se for muito longa */
    font-size: 12px;
    --width: 80%;
    border-bottom: 1px solid lightgray;
    --padding-bottom: 10px;
    --padding-top: 10px;
    padding: 10px;
    cursor: pointer;
}

.divEnderecoEntregaMap:hover {
    background: #7bc2f5;
    transition: all .3s;
}

.divEnderecoEntregaMap:last-child {
    border-bottom: none;
    --padding-bottom: 0px;
    --padding-top: 0px;
}

.divBtnsEditAndAddObsItem {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

.divModalFormaPagamentoDinheiro {
    display: flex;
    gap: 10px;
}

.divObsDoItemPDV {
    display: flex;
    gap: 5px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
    font-weight: 600;
    padding: 5px;
}

.contentLeftModalPagamentos {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 15px;
    width: 70%;
    text-align: center;
}

.divPagamentoSelecionadoContent {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;

    span {
        font-size: 12px;
    }
}

.divFormaPagamentoDividido {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;

    button {
        background: white;
        color: rgb(16, 149, 243);
        border: 1px solid rgb(16, 149, 243);
        border-radius: 5px;
        padding: 5px 10px;
        font-size: 14px;
        cursor: pointer;
        width: 50%;
    }

    button:hover {
        background: #7bc2f5;
        color: white;
        transition: all .3s;
    }
}

.summary-container-modalPagamentos {
    margin: 0 auto;
    text-align: right;
    padding: 20px 10px;
}

.summary-item-modalPagamentos {
    display: flex;
    justify-content: space-between;
    --margin: 8px 0;
    font-size: 14px;
}

.summary-item-modalPagamentos.total-modalPagamentos {
    font-weight: bold;
    font-size: 16px;
    border-bottom: 1px solid #ddd;
    --padding-top: 8px;
}

.message-modalPagamentos {
    text-align: center;
    margin: 16px 0;
    font-size: 12px;
    color: #666;
    height: 120px;
}

.divFormaPaymentDivididoPai {
    width: 75%;
    display: flex;
    flex-direction: column;
}

.divFormaPaymentDividido {
    display: flex;
    align-items: start;
    min-width: 260px;
    justify-content: space-between;
}

.pagamentosLancados-modalPagamentos {
    text-align: center;
    margin: 16px 0;
    font-size: 12px;
    color: #666;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    max-height: 120px;
    overflow-y: scroll;
    overflow-x: hidden;

    span {
        font-weight: 600;
    }
}

.missing-amount-modalPagamentos {
    display: flex;
    justify-content: space-between;
    background-color: #ffe5e5;
    color: orangered;
    padding: 8px;
    border-radius: 4px;
    font-weight: 600;
    margin-top: 16px;
    font-size: 16px;
}


.adicionais-group {
    padding: 0px 0px 5px 30px;
    font-size: 12px;
}

.adicional-item {
    display: flex;
    gap: 5px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    padding-left: 15px;
}

.titleAdicionalCart {
    min-width: 120px;
    font-size: 11px;
}

.categoria-item:hover {
    background-color: rgb(49, 140, 213);

    span {
        color: white;
    }
}

.categoria-item-title {
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: left;
    margin: 5px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 15px;
}

.itens-cardapio {
    width: 80%;
}


.overflow-itens {
    width: 80%;
    overflow-y: scroll;
    padding: 0px 15px 10px 15px;
    --height: calc(100vh - 240px);
    scrollbar-width: none;
}

.divBtnSendToCartPdv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    span {
        color: white;
        font-size: 12px;
    }

    button {
        --height: 50px;
        background: rgb(49, 140, 213);
    }
}

@keyframes bounce-icon-cart {
    0% {
        font-size: 12px;
        /* Tamanho mínimo */
    }

    50% {
        font-size: 16px;
        /* Tamanho original */
    }

    100% {
        font-size: 12px;
        /* Tamanho mínimo novamente */
    }
}

.anim-icon-cart {
    animation: bounce-icon-cart 1.5s infinite;
    /* Animação com loop infinito */
    display: inline-block;
}

.btnSendCartPdv {
    display: inline-block;
    padding: 0.75rem 1.25rem;
    /*border-radius: 10rem;*/
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.15rem;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: transparent;
    border: 2px solid #0cf;
    cursor: pointer;
    transition: all 0.3s;
    animation: colorAnimation 1s infinite;
    /* Inicia a animação a cada 1 segundo */

    /* A animação para cor do texto */
    &:hover {
        color: #fff;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0cf;
        /*border-radius: 10rem;*/
        z-index: -2;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: rgb(49, 140, 213);
        /* Cor mais escura */
        /*border-radius: 10rem;*/
        transition: all 0.3s;
        z-index: -1;
        animation: expandAnimation 2s infinite;
        /* Animação de expansão */
    }
}

/* Animação de cor do texto */
@keyframes colorAnimation {

    0%,
    100% {
        color: #fff;
    }
}

/* Animação de expansão da cor de fundo */
@keyframes expandAnimation {

    0%,
    100% {
        width: 0%;
    }

    50% {
        width: 100%;
    }
}

.btnSendCartPdv:hover {
    --background: #0D6EFD;
}

.itens-categoria {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    gap: 10px;
    flex-wrap: wrap;
}

.itens {
    cursor: pointer;
    border-radius: 5px;
    width: 100px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #D7D8DD;
    transition: all .2s;
    position: relative;
}

.itens:hover {
    background-color: rgb(49, 140, 213);
    border: 1px solid rgb(49, 140, 213);

    .divItemImg img {
        transform: scale(2);
    }
}

.itens:hover .divItemName {
    border: none;

    span {
        color: white;
    }
}

.divItemImg {
    height: 75px;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 5px 5px 0 0;

    img {
        border-radius: 10px 10px 0px 0px;
        border-radius: 5px 5px 0 0;
        width: 98px;
        height: 75px;
        object-fit: cover;
        box-sizing: border-box;
        transform: scale(1);
        transition: transform .5s ease-in-out;
    }
}

.divItemName {
    width: 100%;
    height: 50%;
    border-radius: 0 0 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #D7D8DD;
    box-sizing: border-box;
    transition: all .2s;

    span {
        max-width: 86px;
        font-weight: 700;
        font-size: 11px;
        text-transform: uppercase;
        line-height: 15px;
        color: #5a5a5a;
        line-break: auto;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        transition: all .2s;
    }
}

.continue-button.avancar {
    button {
        height: 34px !important;
        width: 130px !important;
        margin-top: 2px !important;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
    }
}

.continue-button.sair {
    button {
        height: 34px !important;
        width: 50px !important;
        margin-top: 2px !important;
        background: lightgray !important;
        color: gray !important;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

.continue-button.observacao {
    button {
        height: 34px !important;
        width: 130px !important;
        margin-top: 2px !important;
        background: lightgray !important;
        color: gray !important;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
    }
}

.sairObservacao {
    display: inline-flex;
    align-items: center;
    width: 20%;
    margin-left: 20px;
}

.pedeja-input {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: max-content;
    align-items: center;
}

.input-number {
    width: 92px;
    position: relative;
    font-family: Roboto, sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    input {
        height: 30px;
        width: 92px;
        border-radius: 5px;
        padding: 0 28px;
        border: 1px solid #D7D8DD;
        font-size: 12px;
        color: #2b2b2b;
        position: relative;
        text-align: center;
    }
}

.item-selected-amount {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    padding-bottom: 6px;
}

.decrement-item {
    transition: .3s;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 2%;
    border: none;
    background-color: #d7d8dd;
    color: #2b2b2b;
    width: 17px;
    height: 17px;
    top: calc(50% - 8.5px);
    left: 6px;
}

.increment-item {
    transition: .3s;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 2%;
    border: none;
    background-color: #d7d8dd;
    color: #2b2b2b;
    width: 17px;
    height: 17px;
    top: calc(50% - 8.5px);
    right: 6px;
}

.divItemSelected {
    width: 100%;
    height: 100%;
    border-radius: 0 0 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    transition: all .2s;
    color: white;

    span {
        max-width: 86px;
        font-weight: 700;
        font-size: 11px;
        text-transform: uppercase;
        line-height: 15px;
        line-break: auto;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        transition: all .2s;
    }
}

.item-price-selected {
    background-color: #fff;
    color: #003e63;
    border-radius: 5px;
    padding: 4px 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    width: max-content;
    transition: all .2s;
}

.divInputItem {
    height: 30px;
    width: 92px;
    border-radius: 5px;
    padding: 0 28px;
    border: 1px solid #D7D8DD;
    font-size: 12px;
    color: #2b2b2b;

    input {
        position: relative;
        text-align: center;
    }
}

.buttons-edo {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px 5px 20px;
    height: 7vh;
    align-items: center;
}

.itens-pedido {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
    font-weight: bold;
    background-color: #ccc;
    font-size: 12px;
    height: 4%;
    align-items: center;
}


.buttons-edit-delete-obs {
    text-align: center;
}

.buttons-edit-delete-obs button {
    border: none;
    box-shadow: 1px 1px 6px lightgray;
    height: 40px;
    border: 1px solid #bbb;
    color: #bbb;
    font-size: 12px;
    min-width: 85px;
    background: white;
    padding: 0px 5px;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.buttons-edit-delete-obs button:hover {
    color: rgb(49, 140, 213);
    border: 1px solid rgb(49, 140, 213);
}

.buttons-edit-delete-obs button a {
    text-decoration: none;
    font-size: 12px;
    font-weight: 700;
    color: var(--main-color-pedeja) !important;
}

.buttons-edit-delete-obs button svg {
    color: var(--main-color-pedeja) !important;
}

.lista-pedidos {
    display: flex;
    gap: 5px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    height: 35px;
    padding-left: 15px;
    height: 50px;
}

.content-pedidos {
    height: 49%;
    min-height: 180px;
    --max-width: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.resumo-valores {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    padding: 0 20px;
    height: 90px;
}

.valores-categoria {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #5a5a5a;
    position: relative;
}

.separador-valor {
    width: 100%;
    box-sizing: border-box;
    background-color: #e1e1e1;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: 35px;
}

.box-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    --height: 1px;
    box-sizing: border-box;
    padding: 5px 10px 5px;
    transition: all .2s;
    margin-bottom: 1px;
}

@media (max-width: 1400px) {
    .box-info {
        gap: 5px;
    }
}

.informacoes-pedido {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 10px 5px;
    transition: all .2s;
}

.container-cliente-pedido {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
}

.cliente-pedido-data {
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    gap: 10px;
    max-width: 768px;
    position: relative;
    border: 2px solid rgb(49, 140, 213);
}

.telefone {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    z-index: 2;
    background-color: #fff;
}

.input-data-cliente {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    position: relative;

    input {
        border: none;
        outline: none;
        height: 30px;
    }
}

.client-data-separator {
    width: 1px;
    background-color: rgb(49, 140, 213);
    height: 40px;
    z-index: 2;
}

.grid-botoes-pedido {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
}

.button-columnPDV {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;

    button {
        min-width: 30px;
        padding: 0 30px;
        height: 30px;
        width: 100%;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #bbb;
        outline: none;
        border-radius: 5px;
        box-sizing: border-box;
        font-weight: 600;
        font-family: Roboto, sans-serif;
        cursor: pointer;
        color: #bbb;
        background-color: white;
        transition: background-color .3s;
    }
}

.btn-activated {
    border: 1px solid rgb(49, 140, 213) !important;
    color: rgb(49, 140, 213);
}

.btn-activated:hover {
    background-color: rgb(49, 140, 213) !important;
    color: white !important;
}

.btn-disabled {
    border: 1px solid #bbb !important;
    color: #bbb !important;
}

.closeModalEditPedido {
    cursor: pointer;
    transition: .2s;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: -10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 100%;
    padding: 3px;
}

.closeModalEditPedido:hover {
    transition: .2s;
    transform: rotate(180deg) scale(1.2);
    width: 28px !important;
    height: 28px !important;
    border: 1px solid gray;
}

@media (max-width: 1300px) {
    .button-columnPDV {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 5px;

        button {
            min-width: 30px;
            padding: 0 30px;
            height: 20px;
            width: 100%;
            font-size: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            outline: none;
            border-radius: 5px;
            box-sizing: border-box;
            font-weight: 500;
            font-family: Roboto, sans-serif;
            cursor: pointer;
            color: #fff;
            background-color: rgb(49, 140, 213);
            transition: background-color .3s;
        }
    }

    .pdv {
        display: flex;
        flex-direction: column;
        padding: 12px;
    }

    .itens-cardapio {
        width: 100%;
    }

    .grid-cardapio {
        width: 100%;
    }

    .gerar-pedido {
        width: 100%;
    }
}


.button-enter {
    min-width: 50px;
    min-height: 40px;
    height: 50px;
    width: 100%;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 5px;
    box-sizing: border-box;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    cursor: pointer;
    color: #fff;
    background-color: rgb(49, 140, 213);
    transition: background-color .3s;
}

@media (max-width: 1400px) {
    .button-enter {
        min-width: 30px;
        height: 30px;
        width: 100%;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        border-radius: 5px;
        box-sizing: border-box;
        font-weight: 500;
        font-family: Roboto, sans-serif;
        cursor: pointer;
        color: #fff;
        background-color: rgb(49, 140, 213);
        transition: background-color .3s;
    }
}

.footer {
    height: 40%;
    box-sizing: border-box;
    border-top: 1px solid lightgray;
}

.adicionaisScrollModalAdd {
    display: flex;
    gap: 10px;
    max-width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-wrap: wrap;
    max-height: 50vh;
}

.modal-dialog {
    max-width: none !important;
}

.modal-body{
    max-height: 65vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.custom-modal-adicionais .modal-content {
    width: 62vw;
    left: 18%;
}

.adicionaisScrollModalAdd {
    scrollbar-color: auto;
    scrollbar-width: auto;
}

/* Estiliza a barra de rolagem */
.adicionaisScrollModalAdd::-webkit-scrollbar {
    width: 7px;
    /* Largura da barra de rolagem */
}

/* Estiliza a parte interna da barra de rolagem */
.adicionaisScrollModalAdd::-webkit-scrollbar-track {
    background: #f0f0f0;
    /* Cor do fundo da barra de rolagem */
    border-radius: 10px;
    /* Cantos arredondados */
}

/* Estiliza a "thumb" da barra de rolagem */
.adicionaisScrollModalAdd::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    /* Cor da "thumb" */
    border-radius: 10px;
    /* Cantos arredondados */
}

/* Cor ao passar o mouse sobre a "thumb" */
.adicionaisScrollModalAdd::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1;
    /* Cor mais escura ao passar o mouse */
}

/* Remove as setas */
.adicionaisScrollModalAdd::-webkit-scrollbar-button {
    display: none;
    /* Remove as setas padrão */
}