.planos{
    display: flex;
    justify-content: center;
    --height: calc(100vh - 140px);
}

.divChoosePlan{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.subscription-layout {
    font-family: Arial, sans-serif;
    background-color: #fff;
    color: #333;
    padding: 20px;
    --border-radius: 8px;
    --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    --margin: 20px;
}

.header h1 {
    font-size: 1.5em;
    margin-bottom: 20px;
}

.buttonFinalizarPagamento{
    background: #0a84ff;
    border-radius:5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border:none;
}

.plans {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    justify-content: center;
}

.plan {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 45%;
}

.plan.recommended {
    --border-color: #007bff;
    display: flex;
    justify-content: space-between;
    --background: aliceblue;
}

.plan.monthly{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.choosePlanTypes{
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 40%;
    min-height: 200px;
    max-height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btnChoosePlan{
    justify-content: center;
    display: flex;
    align-items: center;  
    button{
        padding: 10px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
}

.divBtnContratar{
    justify-content: center;
    display: flex;
    align-items: center;    
}

.plan .title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}

.plan .price {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
}

.plan .price span {
    font-size: 0.5em;
    font-weight: normal;
}

.plan .save {
    margin-bottom: 10px;
    background: midnightblue;
    color: white;
    border-radius: 5px;
    font-size: 12px;
    width: 70%;
}

.plan button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.plan .badge {
    position: relative;
    top: -25px;
    right: 0px;
    background-color: #ffc107;
    color: #333;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.8em;
}

.info p {
    background-color: #e9f5ff;
    color: #333;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.payment-methods .title {
    font-weight: bold;
    margin-bottom: 10px;
}

.payment-methods .methods {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.btnCheck{
    width: 20px;
    border: 1px solid gray;
    height: 20px;
    border-radius: 50%;
}

.creditCardDiscount{
    font-size: 12px;
    background: midnightblue;
    color: white;
    text-align: center;
    border-radius: 5px;
    width: 80%;
}

.pixDiscount{
    font-size: 12px;
    background: lightgrey;
    color: black;
    text-align: center;
    border-radius: 5px;
    width: 110%;
    font-weight: 500;
}

.inputBoxRadioPersonalized{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.payment-methods{
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
}

.planosFormInputs{
    input{
        padding: 0.8rem 0.8rem;
    }
}

.parcelasSelect{
    min-width: auto;
}

.typePersonDiv{
    display: flex;
    --justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
}

.payment-methods .method {
    border: 1px dashed #ccc;
    padding: 15px;
    border-radius: 8px;
    width: 33%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor:pointer;
}

.payment-methods .method:hover {
    border: 1px solid #007bff;
}

.features .title {
    font-weight: bold;
    margin-bottom: 10px;
}

.features .feature-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.features .feature {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 4px;
}

.footerBtnPlanos button {
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

@media (prefers-color-scheme: dark) {
    .subscription-layout {
      background-color: #1c1c1e;
      color: #f2f2f7;
    }
    .plan {
      border-color: #3a3a3c;
    }
    .plan.recommended {
      --border-color: #0a84ff;
    }
    .plan button {
      background-color: #0a84ff;
    }
    .info p {
      background-color: #3a3a3c;
    }
    .payment-methods .method {
      border-color: #3a3a3c;
    }
    .features .feature {
      background-color: #3a3a3c;
    }
    .footerBtnPlanos button {
      background-color: #30d158;
    }
}

.content-info-plan{
    min-height: 400px;
    border: 1px dashed lightgray;
    padding: 20px;
    border-radius: 8px!important;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    text-align: center;
    font-size: 13px;
}

.infoPlanSpanText{
    gap: 5px;
    display: flex;
}

.goToPayments{
    color:#0a84ff;
}

.goToPayments:hover{
    text-decoration: underline;
    cursor: pointer;
}

.changePaymentType{
    background: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: none;
    border: 1px solid #0a84ff;
    color: #0a84ff;
    padding: 10px;
    transition: all 0.3s;
}

.changePaymentType:hover{
    background: #0a84ff;
    color: white;
}

.boxFaturaDetail{
    border: 1px solid gray;
    padding: 10px;
    margin: 10px 0px;
    border-radius: 8px
}

.btnUtilizarDadosCadastrados{
    color: white;
    background-color: #007bff;
    border: none;
    padding: 4px 10px;
    border-radius: 5px;
}

.pagarFaturaBtn{
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    padding: 4px 5px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    transition: all .2s;
}

.pagarFaturaBtn:hover{
    text-decoration:underline;
    color: #333;
}

.openFaturasText{
    text-decoration: underline;
    color: #007bff;
    cursor: pointer;
}

.custom-invoices-modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: auto;
}

.custom-invoices-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-invoices-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.custom-invoices-table th, .custom-invoices-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.custom-invoices-pay-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: green;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background 0.3s;
    gap:10px;
}

.custom-invoices-pay-button:hover {
    background: darkgreen;
    color: black;
}

.custom-invoices-disabled-button {
    background: grey;
    cursor: not-allowed;
}

.custom-invoices-open-modal-button {
    background: black;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.custom-invoices-open-modal-button:hover {
    background: black;
}

.custom-invoices-close-button {
    background: red;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}
