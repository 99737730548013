.react-datepicker__input-container {
    display: flex;
    align-items: center;
  }
  
  .react-datepicker__input-container span {
    margin: 0 8px;
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .react-datepicker {
    font-family: Arial, Helvetica, sans-serif;
  }

  .react-datepicker__close-icon{
    right: 62px!important;
  }