.containerAddEmpresa{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
}

.divSidePanel{
    display: flex;
    flex-direction: row;
    min-width: 30%;
    background-color: #fafafa;
    max-width: 30%;
    position: fixed;
    height: 100%;
}

.divSidePanelContent{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0;
    justify-content: center;
    align-items: start;
    margin: 0 0 0 20%;
}

.divMenuItem{
    padding-top:20px;
}

.divLogo{
    --padding-left: 3px;
}

.menuItemSelected{
    background: black;
    width: 3px;
    height: 34px;
    position: relative;
    top: 5px;
    left: -20px;
}

.btnOptionsAddEmpresa{
    font-size:18px; 
    font-weight:600;
    padding: 10px 0px;
    color:#9d9d9d;
    cursor: pointer;
}

.checkedOptAddEmpresa{
    color:black;
    cursor:text;
    margin-left: -3px;
}

.containerAddEmpresaForm{
    display: flex;
    position: absolute;
    flex-direction: row;
    background: white;
    min-height: 100vh;
    min-width: 70%;
    max-width: 70%;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    right: 0;
}

.formAddEmpresa{
    height: 60%;
    padding-top: 10%;
    margin-bottom: 35px;
    width: 60%;
}

.informationsAccount{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.btnBackCadastros{
    background-color: white;
    color: #007bff;
    border: 1px solid #007bff;
    min-width: 200px;
    transition: all .2s;
    padding: 10px;
    border-radius: 4px;
    min-height: 40px;
    &:hover{
        background-color: #007bff;
        color:white;
    }
}

.btnFwdCadastros{
    background-color: #007bff;
    border: none;
    min-width: 200px;
    transition: all .2s;
    padding: 10px;
    border-radius: 4px;
    min-height: 40px;
    &:hover{
        background-color: #64afff;
    }
}

.btnQuestionPC{
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    color: grey; 
    min-height: 40px;
}

.btnQuestionPCsim{
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #007bff;
    color: white; 
    min-height: 40px;
}

.btnQuestionPCnao{
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #c43d4f;
    color: white; 
    min-height: 40px;
}

.divBairro{
    width:40%;
}

.divLogradouro{
    width:56%;
}

.divNumero{
    width:20%;
}

.divComplemento{
    width:76%;
}

.selectdiv:after {
    top:22px!important;
}

.linkToImportInput::placeholder{
    color: rgb(200, 200, 200);
}

.waitingFinishFormEmpresa{
    color: black;
    position: absolute;
    z-index: 100;
    background: white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    box-shadow: 0px 0px 5px 0px gray;
}