
.input-box.descricaoItem{
    input{        
        height: 200px;
    }
}

.divContainerAddItem{
    width: 48%;
    display: flex;
    flex-direction: column;
}

.divImgAddItem{
    --width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.zindexForLoading{
    z-index: 100;
    fill: rgb(49, 140, 213);
    height: 80px;
    width: 80px;
}

.zIndexForLoadingSaveButton{
    z-index: 100;
    fill: white;
    height: 30px;
    width: 30px;
    margin-left: 38%;
}

.btnAvancarTelasEditItem{
    height: 30px;
    position: relative;
    --left: 0px;
    --top: 10px;
    border: none;
    border-radius: 5px !important;
    padding: 0px 10px;
    background: rgb(49, 140, 213);
    display: flex;
    align-items: center;
    gap: 5px;
}

.divBtnsBackFoward{
    display: flex;
    flex-direction: column;
    justify-content: end;
}
.btnsBackFoward{
    display: flex;
    gap: 5px;
}

.inputGroup-adicinaItem{
    justify-content: start!important;
    gap: 40px;
    grid-row-gap: 0px;
    display: flex;
    flex-direction: row!important;
    max-width: 65%!important;
}

.inputGroup-etapasItem{
    width: 220px!important;
    height: 240px;
    background: white;
    padding: 0!important;
    --margin-top: 0!important;
}

.contentItemComplete{
    display: flex;
    flex-direction: row;    
    gap:20px;
    justify-content: center;
}

.text-wrap-description {
    width: 100%!important;
    height: 150px!important;
    white-space: pre-wrap!important;
    overflow: auto!important;
    border: none;
    box-shadow: 1px 1px 6px lightgray;
    border-radius: 10px;
    margin: 0.6rem 0 0.2rem 0;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
}

.text-wrap-description:hover{
    background: rgb(247,247,247);
}

.text-wrap-description:focus-visible {
    outline: 1px solid #4070f4;
}

.divCounterChar{
    justify-content: end;
    display: flex;
    font-size: 12px;
    color: gray;
}

.formGroupRow{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.formGroupRowPlanos{
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.upload__image-wrapper.imgItem{
    --width: 50%!important;
    --height: 60%!important;
}

.input-box.precoItemCustom{
    input:focus-visible{
        outline: #4070f4 2px solid!important;
    }
    input{
        margin: 0px!important;
        border-left: 1px solid #0000001c!important;
        padding: 0.8rem 0.5rem!important;
        font-size:16px!important;
    }
}

.btnCreateGroupAdicionais{
    height: 30px;
    min-height: 50px;
    position: relative;
    border: none;
    border-radius: 5px !important;
    padding: 0px 10px;
    background: rgb(49, 140, 213);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.ant-input-group-addon {
    border: none!important;
    box-shadow: 1px 1px 6px lightgray!important;
    min-width: 35px;
    align-items: center;
}

.iconLanche{
    display: flex;
    height: 75px;
    width: 75px;
    border-radius: 10px;
    background: transparent;
    justify-content: center;
    align-items: center;
    transition: all .2s;
    border: 1px solid #98c5ea;
    svg{        
        color: #0c9abe;
    }
}

.divUploadImgItem{
    justify-content: center;
    padding:15px;
}

.divUploadImgItem:hover .iconLanche{
    background: rgb(49, 140, 213)!important;
    svg{
        color: white;
    }
}

.boxImgToFit{
    height: 140px;
    width: 140px;
    background: white;
    border: 1px solid lightgray;
    margin: 5px;
    border-radius: 5px;
}

.btnAttImgItem{
    z-index: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    width: 35px;
    height: 35px;
    border-radius: 6px;
    background-color: #e1e1e1;   
}

.btnDelImgItem{
    z-index: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    width: 35px;
    height: 35px;
    border-radius: 6px;
    background-color: #e1e1e1;
}

.image-item__btn-wrapper.addItemPart{
    display: flex;
    gap: 10px;
    justify-content: center;
}

.etapasAddItem{
    width: 100%;
    padding-left: 20px;
    display: flex;
    align-items: center;
    label{
        cursor: pointer;
        font-weight: bold;
        font-size: 14px;
        color: #cccccc;
    }
}

.etapasAddItem.etapaAtiva{
    --cursor: pointer;
    label{
        color: rgb(49, 140, 213)!important;
    }
}

.titulo-item{
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 5px;
    --box-shadow: 0px 0px 3px gray;
}

.iconWhenImgNull{
    height: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    svg{
        color:rgb(60, 60, 60);
    }
}

.nenhumItem{
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    /* text-transform: uppercase; */
    color: gray;
    opacity: 0.6;
    font-size: 16px;
    font-weight: 700;
}

.resultItem{
    border-radius:10px;
    display: inline-block;
    --justify-content: space-between;
    --padding: 20px;
    align-items: center;
    --min-height: 100px;
    width: 100%;
}

.itens-categoriaHorizontal{
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    gap: 10px;
    flex-direction: column;
}

.overflow-itensHorizontal{
    width: 80%;
    overflow-y: scroll;
    --overflow-x: scroll;
    padding: 0px 15px 10px 15px;
    height: calc(100vh - 280px);
}

.categoria-cardapio.horizontal{
    height: calc(100vh - 280px)!important;
}

.center-cardapio.horizontal {
    height: calc(100vh - 280px)!important;
}

.itensList {
    --cursor: pointer;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    --border: 1px solid #D7D8DD;
    border: 1px solid #7e7e7e;
    transition: all .2s;
    position: relative;  
}


.itensList:hover{
    background-color: rgb(49, 140, 213);
    border: 1px solid rgb(49, 140, 213);
    .divItemImgList img {
        transform: scale(2);
    }

    span{
        color: white;
    }
    svg{
        color:white;
    }
    label{
        color: white;
    }
    .actionsItem{         
        border: 1px solid white!important;       
        color: white!important; 
    }
    .icon-btn{
        border: 1px solid white!important;
    }
}

.itensList:hover .divItemName { 
    border:none;
    span{
        color: white;
    }
} 

.divItemImgList{
    height: 75px;
    width: 120px;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 5px 0px 0px 5px;
    img{
        --border-radius: 10px 10px 0px 0px;
        border-radius: 5px 0px 0px 5px;
        width: 98px;
        height: 73px;
        object-fit: cover;
        box-sizing: border-box;
        transform: scale(1);
        transition: transform .5s ease-in-out;
        margin-top: 1px;
    }
}

.divItemNameList{
    width: 100%;
    height: 100%;
    border-radius: 0 0 5px 5px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px;
    --border-top: 1px solid #D7D8DD;
    box-sizing: border-box;
    transition: all .2s;

    span{
        --max-width: 86px;
        --min-width: 80px;
        font-weight: 700;
        font-size: 11px;
        --text-transform: uppercase;
        line-height: 15px;
        color: #5a5a5a;
        line-break: auto;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        transition: all .2s;
    }
}

.divItemNameList:hover{
    span{
        color:white;
    }
    svg{
        color:white;
    }
    .actionsItem{         
        border: 1px solid white!important;       
        color: white!important; 
    }
    .icon-btn{
        border: 1px solid white!important;
    }
}

.divEsgotarItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.actionsItem{
    cursor: pointer;
    border: 1px solid #9D9D9D !important;
    border-radius: 4px;
    padding: 2px 2px;
    max-width: 100px !important;
    color: #9D9D9D !important;
}

.actionsItem:hover{
    background:#57B4FF!important;
    color:#fff!important;
}

.icon-btn:hover{
    background:#57B4FF!important;
}

.optionsDropdown {
    border: 1px solid lightgray;
    border-radius: 4px;
    background: white;
    position: absolute;
    z-index: 1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    font-size: 12px;
    max-width: 150px;
    right: 10px;
}

.optionItem {
    padding: 7px 10px;
    cursor: pointer;
    transition: background 0.3s;
    display: flex;
    align-items: center;
    gap: 5px;
    svg{
        color: #5a5a5a!important;
    
    }
}

.optionItem:hover {
    background: #f0f0f0;
}

.optionItem:not(:last-child) {
    border-bottom: 1px solid #ddd;
}

.divItemPrice{
    display: flex;
    flex-direction: row;    
    gap: 3px;
    align-items: center;   
    width: 120px; 
    justify-content: center;
}

.inputPersonalizedItem{
    span{
        max-width: 150px!important;
        display: flex!important;
    }
}

.titlePlusDragIcon{
    display: flex;
    flex-direction: row;    
    gap: 10px;
    align-items: center;
    min-width: 280px;
}

.divLinkAcoes{
    display: flex;
    flex-direction: row;    
    gap: 10px;
    align-items: center;
}


.itemsOptJustifyEnd{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.asyncSelectInput{
    input{        
        box-shadow: none!important;
    }
}

.asyncSelectInputAdicionais{
    height: auto!important;
    input{        
        box-shadow: none!important;
    }
}

.gerenciarAdicionaisBtn{
    position: absolute;
    right: 15px;
    border-radius: 5px;
    background: #F1F1F0;
    padding: 5px 10px;
    font-size: 15px;
    color: darkslategray;
    font-weight: 500;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: all .3s;
}

.gerenciarAdicionaisBtn:hover{
    background: #e1e1e1!important;
}