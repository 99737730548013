.status-aberto{
    color: #fff;
    background-color: #ccce70;
    border-color: #ccce70;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
    border-radius: 10px;
    margin-top: 2%;
}
.status-aberto span{
    font-size: 12px;
}

.status-enviado{
    color: #fff;
    background-color: #70c3ce;
    border-color: #70c3ce;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
    border-radius: 10px;
    margin-top: 2%;
}
.status-enviado span{
    font-size: 12px;
}

.status-faturado{
    color: #fff;
    background-color: #70ce86;
    border-color: #70ce86;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
    border-radius: 10px;
    margin-top: 2%;
}
.status-faturado span{
    font-size: 12px;
}

.status-cancelado{
    color: #fff;
    background-color: #ce7070;
    border-color: #ce7070;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
    border-radius: 10px;
    margin-top: 2%;
}
.status-cancelado span{
    font-size: 12px;
}