.contentInfoPlanosTypes{
    gap: 20px;
    display: flex;
    flex-direction: row;
    margin-right: 10%;
}

.rowAddPlanos{
    display: flex;
    justify-content: space-between;
    width: 100%;
}