.containerReportDesempenho{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

/* style.css */
.desempenho-container {
    display: flex;
    width: 100%;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.desempenho-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: white;
    font-size: 14px;
}

.bgWhiteCards{
    background: white;
    border-radius: 8px;
    width: 23%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-info{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.desempenho-azul {
    background-color: #007bff;
}

.desempenho-laranja {
    background-color: #ffc107;
}

.desempenho-amarelo {
    background-color: #fd7e14;
}

.desempenho-verde {
    background-color: #28a745;
}

.desempenho-item span {
    font-size: 16px;
}

.desempenho-item strong {
    font-size: 18px;
    margin-top: 5px;
}

/* style.css */
.desempenho-adicional-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 10px;
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    flex-direction: column;
}

.desempenho-metricas {
    display: flex;
    align-items: center;
    justify-content: space-around;
    --width: 50%;
    width: 100%;
}

.metrica {
    text-align: center;
}

.metrica span {
    display: block;
    color: #333;
    font-size: 16px;
}

.metrica strong {
    display: block;
    font-size: 24px;
    color: #000;
}

.metrica small {
    color: green;
    font-size: 12px;
}

.desempenho-graficos {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
}

.grafico, .grafico-duplo {
    text-align: center;
    margin-left: 20px;
}

.grafico{
    span{
        position: relative;
        top: -50px;
    }
}

.grafico-circulo {
    background-color: #eee;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin: 10px auto;
    color: #333;
}

.grafico-duplo {
    display: flex;
}

.periodo-selecao{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.periodo-selecao button {
    padding: 5px 10px;
    margin: 0 5px;
    background: #ccc;
    border: none;
    cursor: pointer;
}

.periodo-selecao button.ativo {
    background: #007bff;
    color: white;
}
