.roboCfg-container {
  display: flex;
  font-family: Arial, sans-serif;
  width: 100%;
}

.roboCfg-sidebar {
  width: 40%;
  border-right: 1px solid #ccc;
  padding: 7px;
}

.roboCfg-message-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  max-height: 485px;
  padding-right: 7px;
}

.roboCfg-message-list {
  scrollbar-color: auto; /* Cor customizada apenas para este elemento */
  scrollbar-width: auto; /* Largura customizada */
}

/* Estiliza a barra de rolagem */
.roboCfg-message-list::-webkit-scrollbar {
  width: 7px; /* Largura da barra de rolagem */
}

/* Estiliza a parte interna da barra de rolagem */
.roboCfg-message-list::-webkit-scrollbar-track {
  background: #f0f0f0; /* Cor do fundo da barra de rolagem */
  border-radius: 10px; /* Cantos arredondados */
}

/* Estiliza a "thumb" da barra de rolagem */
.roboCfg-message-list::-webkit-scrollbar-thumb {
  background: #c1c1c1; /* Cor da "thumb" */
  border-radius: 10px; /* Cantos arredondados */
}

/* Cor ao passar o mouse sobre a "thumb" */
.roboCfg-message-list::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1; /* Cor mais escura ao passar o mouse */
}

/* Remove as setas */
.roboCfg-message-list::-webkit-scrollbar-button {
  display: none; /* Remove as setas padrão */
}

.roboCfg-message-item {
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  --align-items: center;
  flex-direction: column;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
  margin-bottom: 8px;
  font-size: 13px;
}

.roboCfg-message-item:hover {
  background-color: #f0f0f0;
}

.roboCfg-selected {
  background-color: #d9f2ff;
}

.checkBoxContentMsg{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(200, 200, 200);
  padding-top: 5px;
  align-items: center;
  margin-top: 5px;
}

.roboCfg-edit-button-ativo {
  background-color: #ffffff;
  border: 1px solid #007aff;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 12px;
  cursor: pointer;
  color: #007aff;
  font-weight: 600;
}

.roboCfg-edit-button-inativo {
  background-color: #ffffff;
  border: 1px solid red;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 12px;
  cursor: pointer;
  color: red;
}

.roboCfg-main {
  flex-grow: 1;
  padding: 10px;
}

.roboCfg-preview {
  margin-bottom: 20px;
  padding: 5px;
}

.roboCfg-chat {
  display: flex;
  flex-direction: column;
}

.roboCfg-chat-bubble {
  margin: 5px 0;
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
}

.roboCfg-client {
  background-color: #e5e5ea;
  align-self: flex-start;
}

.roboCfg-robot {
  background-color: #007aff;
  color: white;
  align-self: flex-end;
}

.roboCfg-editor {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.roboCfg-textarea {
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.roboCfg-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  justify-content: center;
}

.roboCfg-button {
  padding: 5px 10px;
  border: 1px solid #007aff;
  background-color: #f0f8ff;
  border-radius: 5px;
  cursor: pointer;
  color: #007aff;
}

.roboCfg-save-cancel {
  display: flex;
  justify-content: center;
}

.roboCfg-save-button, .roboCfg-cancel-button {
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.roboCfg-save-button {
  background-color: #28a745;
  color: white;
}

.roboCfg-cancel-button {
  background-color: #dc3545;
  color: white;
}
