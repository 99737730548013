.schedule-settings {
  font-family: Arial, sans-serif;
  --max-width: 600px;
  --margin: 0 auto;
}

.timeInputFuncionamento{
  display: flex;
  gap: 5px;
  align-items: center;
  span{
    font-weight: 600;
  }
}

.divInputsFuncionamento{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.btnAddHorarioFuncionamento{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  color: #007bff;
  padding: 0px 10px 10px 10px;
  cursor: pointer;
  width: 55%;
}

.btnRemoveHorarioFuncionamento{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  color: #007bff;
  padding: 10px;
  cursor: pointer;
  i{    
    font-style: normal;
  }
}

.schedule-settings h3 {
  margin-bottom: 20px;
}

.timezone-select, .availability-options, .day-selector, .time-range-selector {
  margin-bottom: 20px;
}

.timezone-select select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
}

.availability-options .option {
  margin-bottom: 10px;
}

.availability-options input[type="radio"] {
  margin-right: 5px;
}

.day-selector .days button {
  margin-right: 5px;
  padding: 5px 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

.day-selector .days button.active {
  background-color: #007bff;
  color: white;
}

.time-range-selector label {
  display: block;
  margin-bottom: 5px;
}

.time-range-selector input {
  padding: 5px;
  margin-right: 5px;
}

.time-range-selector span {
  margin: 0 10px;
}

.simpleResetButton{
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  background: rgb(49, 140, 213);
  margin-top: 5px;
  transition: all .2s;
}

.simpleResetButton:hover{
  background: red;
}

@media (max-width: 1100px) {
  .input-group {
    width: 100% !important;
    max-width: 450px!important;
    justify-content: space-evenly !important;;
  }
}

