@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    --font-family: 'Open Sans', sans-serif;
    font-family: "Poppins", sans-serif;
}

* ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}

* ::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 10px;
}

* ::-webkit-scrollbar-thumb {
    background: #999;
}

* ::-webkit-scrollbar-thumb:hover {
    background: #777;
}

/*@supports (scrollbar-color: #999 #f5f5f5) {
    * {
        scrollbar-color: #999 #f5f5f5;
        scrollbar-width: thin;
    }

}*/

:root {
    --main-button-color: #0c9abe;
    --main-backButton-color: #ff4c4c;
    height:calc(100vh)!important;
}

html{
    background: rgb(247,247,247);
}

.container {
    width: 100%;
    height: 100vh;
    display: flex;
    --box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.212);
}



.list {
    --width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    --background-color: #fff;
    background-color:rgb(247,247,247)!important;
    padding: 3rem;
    padding-left: 8rem;
}

.list-header-entregadores {
    margin-bottom: 0rem!important;
    display: flex;
    justify-content: space-between;
    --width:100%;
}

.login-button {
    display: flex;
    align-items: center;
}

.login-button button {
    border: none;
    background: linear-gradient(to left, #2D74FF, #07BFFF);
    padding: 0.4rem 1rem;
    border-radius: 5px;
    cursor: pointer;
}

.login-button button:hover {
    background: linear-gradient(to left, #2D74FF, #07BFFF);
}

.login-button button a {
    text-decoration: none;
    font-weight: 500;
    color: #fff;
}

.list-header h1::after {
    content: '';
    display: block;
    width: 5rem;
    height: 0.3rem;
    --background-color: #4070f4;
    background: linear-gradient(to left, #2D74FF, #07BFFF);
    margin: 0 auto;
    position: absolute;
    border-radius: 10px;
}

.input-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 0;
}

.input-box-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.1rem;
    width: 100%;
}

.input-box-list i {
    position: absolute;
}
    
.icon {
    padding: 20px;
    min-width: 40px;
}
    
.input-field {
    width: 100%;
    padding: 10px;
    --text-align: center;
}

.input-box-list input {
    margin: 0.6rem 0;
    padding: 0.8rem 3.5rem;
    border: none;
    border-radius: 10px;
    box-shadow: 1px 1px 6px lightgray;
    font-size: 0.8rem;
}

.input-box-list input:hover {
    background-color: #eeeeee75;
}

.input-box-list input:focus-visible {
    outline: 1px solid #4070f4;
}

.input-box-list label,
.gender-title h6 {
    font-size: 0.75rem;
    font-weight: 600;
    color: #000000c0;
}

.input-box-list input::placeholder {
    color: #000000be;
}

.gender-group {
    display: flex;
    justify-content: space-between;
    margin-top: 0.62rem;
    padding: 0 .5rem;
}

.gender-input {
    display: flex;
    align-items: center;
}

.gender-input input {
    margin-right: 0.35rem;
}

.gender-input label {
    font-size: 0.81rem;
    font-weight: 600;
    color: #000000c0;
}


.div-buttons{
    display: flex;
    justify-content: space-between;
    --width: 18%;
}

.refresh-button{
    text-align: center;
}

.refresh-button button {
    --width: 30%;
    --margin-top: 2.5rem;
    border: none;
    box-shadow: 1px 1px 6px lightgray;

    margin-top:12px; 
    height:49.6px;
    background:white;
    border:solid 2px var(--main-button-color);
    width:150px;
    
    background: -webkit-linear-gradient(to left, #2D74FF, #07BFFF);
    background: -o-linear-gradient(to left, #2D74FF, #07BFFF);
    background: -moz-linear-gradient(to left, #2D74FF, #07BFFF);
    background: linear-gradient(to left, #2D74FF, #07BFFF);
    background: white!important;

    padding: 0.62rem;
    border-radius: 5px;
    cursor: pointer;
}

.refresh-button button:hover {
    background: -webkit-linear-gradient(to left, #4281FF, #51D2FF);
    background: -o-linear-gradient(to left, #4281FF, #51D2FF);
    background: -moz-linear-gradient(to left, #4281FF, #51D2FF);
    background: linear-gradient(to left, #4281FF, #51D2FF);
}

.refresh-button button a {
    text-decoration: none;
    font-size: 0.93rem;
    font-weight: 500;
    color: var(--main-button-color)!important;
}

.refresh-button button svg {
    color: var(--main-button-color)!important;
}


.continue-button{
    text-align: center;
    margin-right: 10px; 
    width: 150px;   
}

.continue-button button {
    --width: 30%;
    --margin-top: 2.5rem;
    border: none;
    box-shadow: 1px 1px 6px lightgray;

    margin-top:12px!important; 
    height:49.6px!important; 
    width:100%!important;
    --margin-left:-10px!important;
    --margin-right:10px!important;
    
    --background: -webkit-linear-gradient(to left, #2D74FF, #07BFFF);
    --background: -o-linear-gradient(to left, #2D74FF, #07BFFF);
    --background: -moz-linear-gradient(to left, #2D74FF, #07BFFF);
    --background: linear-gradient(to left, #2D74FF, #07BFFF);
    background: var(--main-button-color)!important;

    padding: 0.62rem;
    border-radius: 5px;
    cursor: pointer;
}

.continue-button button:hover {
    background: -webkit-linear-gradient(to left, #4281FF, #51D2FF);
    background: -o-linear-gradient(to left, #4281FF, #51D2FF);
    background: -moz-linear-gradient(to left, #4281FF, #51D2FF);
    background: linear-gradient(to left, #4281FF, #51D2FF);
}

.continue-button button a {
    text-decoration: none;
    font-size: 0.93rem;
    font-weight: 500;
    color: #fff;
}

.export-button{
    text-align: center;
}

.export-button button {
    --width: 30%;
    --margin-top: 2.5rem;
    border: none;
    box-shadow: 1px 1px 6px lightgray;

    margin-top:12px; 
    margin-right:10px!important;
    height:49.6px;
    background:white;
    border:solid 2px var(--main-button-color);
    width:150px;
    
    background: -webkit-linear-gradient(to left, #2D74FF, #07BFFF);
    background: -o-linear-gradient(to left, #2D74FF, #07BFFF);
    background: -moz-linear-gradient(to left, #2D74FF, #07BFFF);
    background: linear-gradient(to left, #2D74FF, #07BFFF);
    background: white!important;

    padding: 0.62rem;
    border-radius: 5px;
    cursor: pointer;
}

.export-button button:hover {
    background: -webkit-linear-gradient(to left, #4281FF, #51D2FF);
    background: -o-linear-gradient(to left, #4281FF, #51D2FF);
    background: -moz-linear-gradient(to left, #4281FF, #51D2FF);
    background: linear-gradient(to left, #4281FF, #51D2FF);
}

.export-button button a {
    text-decoration: none;
    font-size: 0.93rem;
    font-weight: 500;
    color: var(--main-button-color)!important;
}

.export-button button svg {
    color: var(--main-button-color)!important;
}


.additem-button{
    text-align: center;    
}

.additem-button button {
    --width: 30%;
    --margin-top: 2.5rem;
    border: none;
    box-shadow: 1px 1px 6px lightgray;

    margin-top:12px!important; 
    height:49.6px!important; 
    width:200px!important;
    --margin-left:-10px!important;
    margin-right:10px!important;
    
    --background: -webkit-linear-gradient(to left, #2D74FF, #07BFFF);
    --background: -o-linear-gradient(to left, #2D74FF, #07BFFF);
    --background: -moz-linear-gradient(to left, #2D74FF, #07BFFF);
    --background: linear-gradient(to left, #2D74FF, #07BFFF);
    background: var(--main-button-color)!important;

    padding: 0.62rem;
    border-radius: 5px;
    cursor: pointer;
}

.additem-button button:hover {
    background: -webkit-linear-gradient(to left, #4281FF, #51D2FF);
    background: -o-linear-gradient(to left, #4281FF, #51D2FF);
    background: -moz-linear-gradient(to left, #4281FF, #51D2FF);
    background: linear-gradient(to left, #4281FF, #51D2FF);
}

.additem-button button a {
    text-decoration: none;
    font-size: 0.93rem;
    font-weight: 500;
    color: #fff;
}


.back-button{
    text-align: center;
}

.back-button button {
    --width: 30%;
    --margin-top: 2.5rem;
    border: none;
    box-shadow: 1px 1px 6px lightgray;

    margin-top:12px; 
    height:49.6px;
    background:white;
    border:solid 2px var(--main-backButton-color);
    width:150px;
    
    background: -webkit-linear-gradient(to left, #2D74FF, #07BFFF);
    background: -o-linear-gradient(to left, #2D74FF, #07BFFF);
    background: -moz-linear-gradient(to left, #2D74FF, #07BFFF);
    background: linear-gradient(to left, #2D74FF, #07BFFF);
    background: white!important;

    padding: 0.62rem;
    border-radius: 5px;
    cursor: pointer;
}

.back-button button:hover {
    background: -webkit-linear-gradient(to left, #4281FF, #51D2FF);
    background: -o-linear-gradient(to left, #4281FF, #51D2FF);
    background: -moz-linear-gradient(to left, #4281FF, #51D2FF);
    background: linear-gradient(to left, #4281FF, #51D2FF);
}

.back-button button a {
    text-decoration: none;
    font-size: 0.93rem;
    font-weight: 500;
    color: var(--main-backButton-color)!important;
}

.back-button button svg {
    color: var(--main-backButton-color)!important;
}

@media screen and (max-width: 1330px) {
    .form-image {
        display: none;
    }
    .container {
        width: 50%;
    }
    .form {
        width: 100%;
    }
}

@media screen and (max-width: 1064px) {
    .container {
        width: 90%;
        height: auto;
    }
    .input-group {
        flex-direction: column;
        z-index: 5;
        padding-right: 5rem;
        max-height: 10rem;
        overflow-y: scroll;
        flex-wrap: nowrap;
    }
    .gender-inputs {
        margin-top: 2rem;
    }
    .gender-group {
        flex-direction: column;
    }
    .gender-title h6 {
        margin: 0;
    }
    .gender-input {
        margin-top: 0.5rem;
    }
}


.error{
    color:red;
    font-size:12px;
    margin-left: 40px;
}




.selectdiv {
    position: relative;
    
    float: left;
    min-width: 200px;
    --margin: 50px 33%;
  }
  
  select::-ms-expand {
  display: none;
  }
  
  .selectdiv:after {
    content: '<>';
    font: 14px "Consolas", monospace;
    color: #333;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 11px;
    
    top: 18px;
    padding: 0 0 2px;
    border-bottom: 1px solid #999;
    
    position: absolute;
    pointer-events: none;
  }
  
    .invalid-option {
        color: gray!important;
      }

  .selectdiv select {
    
    margin: 0.6rem 0;
    padding: 0.8rem 2.5rem;
    border: none;
    border-radius: 10px;
    box-shadow: 1px 1px 6px lightgray;
    font-size: 0.8rem;
    
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
    display: block;
    width: 100%;
    max-width: 320px;
    height: 45px;
    float: right;
    margin: 5px 0px;
    padding: 0px 24px;
    font-size: 0.8rem;
    line-height: 1.75;
    color: #333;
    background-color: #ffffff;
    --background-color:rgb(247,247,247)!important;
    background-image: none;
    --border: 1px solid #cccccc;
    -ms-word-break: normal;
    word-break: normal;
    margin-top: 10px;
    
  }


  .m-5{
    width:100%;
  }
  
  .table{
    background: white!important;
    border-radius: 15px 15px 0 0!important;
    box-shadow: 1px 1px 6px lightgray;
  }

  .table .item th{
    --justify-content: space-between;
    padding: 0.3rem 2rem;
    border-right: 2px solid white;
    font-size: 12px;
    background-color: var(--main-button-color);
    color: white;
  }

  .table .item th label{
    display: flex;
    justify-content: center;
  }

  .table .item thead tr{
    --border-bottom: 2px solid var(--main-button-color);
    border-bottom: none;
  }

  .table .item > :not(caption) > * > * {  
    border-bottom-width:0px; 
  }

  .table .item td{
    /* justify-content: space-between;
    padding: 0.5rem 1.5rem;
    margin-right: 10px;
    border-right: 1px solid rgb(180, 180, 180); */
    padding: 0 0;
    --width: 200px;
  }

  .table .item tbody td input{
    display: flex;
    justify-content: space-between;
    border: 1px solid rgb(200,200,200);
    border-radius: 4px;
    width: 100%;
    padding-left: 4px;
    text-align: center;
  }

  /* .menuItemOpt{
    width: 45px;
  } */

  .table .item tbody tr:nth-child(even) {
    background: #e6f4f8;
  }
  .table .item tbody tr:nth-child(odd) {
    background: #e6f4f8;
  }

  .table .item tbody td a svg{
    margin-bottom: 2px!important;
    vertical-align: initial;
  }

  .table thead tr{
    border-bottom: 2px solid rgb(66, 129, 255);
  }

  .table tbody tr:nth-child(even) {
    background: #FFF;
  }
  .table tbody tr:nth-child(odd) {
    background: #e6f4f8;
  }

  .table tbody td a svg{
    margin-bottom: 20%!important;
  }

  .table .menuItemOpt a svg{
    margin-bottom: 45%!important;
  }

  .result{
    animation-duration: 500ms;
    animation-name:slidein;
  }

  .search {
    animation-duration: 500ms;
    animation-name:slideout;
}

    @keyframes slidein{

        from{
        opacity:0;
        transform: translate3d(0, 10px, 0);
        }
    
        to{
        opacity:1; 
        transform: translate3d(0, 0, 0);
        }
    
    }

    @keyframes slideout{

        from{
        opacity:0;
        transform: translate3d(0, 10px, 0);
        }
    
        to{
        opacity:1;
        transform: translate3d(0, 0, 0);
        }
    
    }

    .loading{
        z-index:20;
        position: absolute;
        top: 45%;
        left: 45%;
        float: left;
        min-width: 100px;
        min-height: 100px;
        padding: 5px 0;
        list-style: none;
        background-color: transparent;
        background-clip: padding-box;
        align-items:center;
        justify-content:center;
        
        animation-name: rotate;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        transition-timing-function:linear;
    }

    @keyframes rotate {
        from {
          -webkit-transform: rotate(0deg);
        }
        to { 
          -webkit-transform: rotate(360deg);
        }
    }
