.divTitleItemAdicionado{
    margin: 18px;
    font-size: 15.4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: 600;
    span{   
        display: flex;
        justify-content: center;
    }
}

.produtoAddChecked{
    display: flex;
    justify-content: center;
    svg{
        font-size: 80px;
        color: limegreen;
    }
}

.divBtnContinueCart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-right: 18px;
    padding-left: 18px;    
    a{
        text-decoration: none!important;
        width: 100%;
    }
}

.buttonContinue{
    width: 100%;
    margin-top: 12px;
    margin-right: 6px;
    margin-left: 6px;
    min-width: 40px;
    padding: 0 10px;
    min-height: 40px;
    font-size: 14px;
    border: 2px solid #1095F3;
    outline: none;
    border-radius: 4px;
    font-weight: 700;
    user-select: none;
    cursor: pointer;
    transition: all .2s;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: transparent;
    color: #1095F3!important;        
}

.buttonCart{
    width: 100%;
    margin-top: 12px;
    min-width: 40px;
    padding: 0 10px;
    min-height: 40px;
    font-size: 14px;
    border: 2px solid #1095F3;
    outline: none;
    border-radius: 4px;
    font-weight: 700;
    user-select: none;
    cursor: pointer;
    transition: all .2s;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: #1095F3;
    color: #FFF !important;       
}
