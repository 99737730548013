.app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .configuration-section-printer {
    width: 100%;
    max-width: 600px;
    background: #fff;
    padding: 20px;
    --box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  
  .field {
    margin-bottom: 15px;
  }
  
  .field label {
    display: block;
    margin-bottom: 5px;
  }
  
  .field input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .download-button {
    display: block;
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    text-align: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .download-button:hover {
    background-color: #0056b3;
  }
  
  .test-printer {
    text-align: center;
  }
  
  .test-printer h2 {
    color: #333;
  }
  
  .test-printer p {
    margin: 10px 0;
  }
  
  .test-printer button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .test-printer button:hover {
    background-color: #218838;
  }

@media (max-width: 1100px) {
  .inputGroup-adicinaItem {
    max-width: 100% !important;
  }
  
}