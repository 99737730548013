*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.container{
    width: 100%;
    margin: 0 auto;

    max-width:100%!important;
    padding:0px!important;
    
    display: flex;
    flex-flow: row wrap;
}

.container-login{
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;

    padding: 15px;

    background-color:#F8FAF9;
}


.container-direita{
    width: 50%;
    min-height: 100vh;

    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;

    padding: 0!important;

    background-color:#1E32FF;
}

.wrap-login{
    width: 90%;
    height: 90%;
    background-color: #FFF;
    border-radius: 10px;

    overflow: hidden;
    padding: 40px 55px 33px 55px;
    --margin-top: 10%;

    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.wrap-header{
    width: 70%;
    height: 85px;
    background-color: #FFF;
    border-radius: 10px;

    position: absolute;

    overflow: hidden;
    margin: 15px;
    margin-left:15%;

    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.imgLogo{
    width: 80px;
    height: 55px;
    margin: 15px;
    margin-left: 40px;
}

.login-form{
    width: 100%;
}

.login-form-title{
    display: block;
    font-size: 30px;
    color: #ADADAD;

    margin-bottom: 30px;

    line-height: 1.2;
    text-align: center;
}

.wrap-input{
    width: 100%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 37px;
}

.input{
    font-size: 15px;
    color:grey;
    line-height: 1.2;
    border: none;
    display: block;
    
    width: 100%;
    height: 45px;

    background-color: transparent;

    padding: 0 5px;
}

.focus-input{
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    pointer-events:none;
    color: #adadad;
}

.focus-input::before{
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;

    background: -webkit-linear-gradient(to left, #2D74FF, #07BFFF);
    background: -o-linear-gradient(to left, #2D74FF, #07BFFF);
    background: -moz-linear-gradient(to left, #2D74FF, #07BFFF);
    background: linear-gradient(to left, #2D74FF, #07BFFF);
}

.focus-input::after{
    font-size: 15px;
    color: #999999;
    line-height: 1.2;

    content: attr(data-placeholder);

    display: block;
    width: 100%;
    position: absolute;
    top: 16px;
    left: 0px;

    padding-left: 5px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

.input:focus{
    outline: 0;
}

.input:focus + .focus-input::after{
    top: -15px;
}

.input:focus + .focus-input::before{
    width: 100%;
}

.has-val + .focus-input::after{
    top: -15px;
}

.container-login-form-btn{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 13px;
}

.login-form-btn{
    font-size: 15px;
    border: none;
    border-radius: 10px;
    color: #fff;

    line-height: 1.2;

    text-transform: uppercase;

    display:flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: 50px;

    background: -webkit-linear-gradient(to left, #2D74FF, #07BFFF);
    background: -o-linear-gradient(to left, #2D74FF, #07BFFF);
    background: -moz-linear-gradient(to left, #2D74FF, #07BFFF);
    background: linear-gradient(to left, #2D74FF, #07BFFF);
}

.login-form-btn:hover{
    cursor: pointer;
}

.text-center{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left!important;
    color:gray;
    font-size: 18px;

    --margin-top: 50px;
}

.txt1{
    font-size: 14px;
    color: gray;
    line-height: 1.5;
    padding-right: 5px;
    text-decoration: none;
}

.txt2{
    font-size: 14px;
    color: #07BFFF;
    line-height: 1.5;
    text-decoration: none;
}

.txt2:hover{
    color: #2D74FF;
}

.link-center{
    position: absolute;
    right: 20px;
    top: 30px;
    --padding: 15px;

}

.link1{
    font-size: 18px;
    color: #ADADAD;
    line-height: 1.5;
    padding-right: 20px;
    text-decoration: none;
}

.link2{
    font-size: 18px;
    color: #ADADAD;
    line-height: 1.5;
    padding-right: 20px;
    text-decoration: none;
}

.link3{
    font-size: 18px;
    color: #ADADAD;
    line-height: 1.5;
    padding-right: 20px;
    text-decoration: none;
}

.link1:hover{
    color:#000;
}

.link2:hover{
    color:#000;
}

.link3:hover{
    color:#000;
}