.react-calendar{    
    border-radius: 10px!important;
    border:none!important;
    box-shadow: 1px 1px 6px lightgray!important;
    font-size: 14px!important;
    width: 20rem!important;
    position: absolute!important;
    margin-top: 80px!important;
    opacity: 0;
    transition: 200ms;
}

.react-calendar.open{    
    border-radius: 10px!important;
    border:none!important;
    box-shadow: 1px 1px 6px lightgray!important;
    font-size: 14px!important;
    width: 20rem!important;
    position: absolute!important;
    margin-top: 80px!important;
    opacity: 1;
    transition: 200ms;
}

.react-calendar * {
    color:rgb(80,80,80)!important;
    border-radius: 10px!important;
}