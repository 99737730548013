.custom-input-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .custom-input {
    width: 100%;
    padding: 10px 40px 10px 10px; /* Ajuste o padding para dar espaço para o ícone */
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .calendar-icon {
    position: absolute;
    right: 10px;
    color: #888;
    pointer-events: none;
  }